import './App.css';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import SignOut from './pages/SignOut';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Welcome from './pages/Welcome';
import AppContainer from './pages/AppContainer';
import NoPage from './pages/NoPage';
import AppPageDoWhat from './pages/AppPageDoWhat';
import AppPageList from './pages/AppPageList';
import AppPageInteractions from './pages/AppPageInteractions';
import OfflineOnboarding from './pages/OfflineOnboarding';
import AppSettings from './pages/AppSettings';
import AppTags from './pages/AppTags';
import AppPageStats from './pages/AppPageStats';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<Welcome />} />
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="signout" element={<SignOut />} />
            <Route path="offline" element={<OfflineOnboarding />} />
            <Route path="app" element={<AppContainer />} >
              <Route path="" element={<AppPageDoWhat />} />
              <Route path="list" element={<AppPageList />} />
              <Route path="history" element={<AppPageInteractions />} />
              <Route path="badges" element={<AppPageStats />} />
              <Route path="settings" element={<AppSettings />} />
              <Route path="tags" element={<AppTags />} />
            </Route>
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
