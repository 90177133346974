import { Doit } from '../pages/AppContainer';

export const sortByLastInteraction = (doits: Doit[]): Doit[] => {
  return doits.sort((a, b) => {
    if (a.lastInteractionAt && b.lastInteractionAt) {
      return a.lastInteractionAt - b.lastInteractionAt;
    } else if (a.lastInteractionAt) {
      return 1;
    } else if (b.lastInteractionAt) {
      return -1;
    } else {
      return 0;
    }
  });
}

export const sortDoitByName = (doits: Doit[]): Doit[] => {
  return doits.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  });
}