import { Box, Button } from "@mui/material";
import Typography from "../components/Typography";

const NoPage = () => {
  return <Box sx={{my:3}}>
    <Typography variant="h2" gutterBottom>
      404
    </Typography>
    <Typography variant="h4" gutterBottom>
      Huh? What page?
    </Typography>
    <Typography variant="body1" gutterBottom>
      Please stop trying to break my app.
    </Typography>
    <Typography variant="body1" gutterBottom>
      I am not fixing your mistakes.
    </Typography>
    <Button
      color="secondary"
      variant="contained"
      size="large"
      sx={{ minWidth: 200, mt: { xs: 2, sm: 4 } }}
      component="a"
      href="/"
    >
      Go away
    </Button>
  </Box>;
};

export default NoPage;