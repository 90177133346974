// All entries should either be a string, or an array of 2 strings.
// If it's an array of 2 strings, the first one will be used when
// procrastinateMode is true, and the second one will be used when
// procrastinateMode is false.
// _ is used to namespace the keys
// kebabCase is used for the key name
const allAppText:any = {
  "en": {
    "appTitle": "Doitlater",
    "welcome_notice_buttonText": [
      "Hang on, I don’t want to procrastinate.",
      "Wait, I thought I was here to procrastinate."
    ],
    "welcome_hero_title": [ 
      "Procrastinate Harder",
      "Procrastinate Softer"
    ],
    "welcome_hero_subtitle": [ 
      "The only app that rewards you for procrastination",
      "Yes, this app also helps with regular motivation"
    ],
    "welcome_hero_signup": "Sign up",
    "welcome_hero_signupSubtitle": "Coming Soon",
    "welcome_hero_local": "Use Locally",
    "welcome_hero_localSubtitle": "No account required",
    "welcome_values_title1": [
      'Where did my time go?',
      'Where did my days go?'
    ],
    "welcome_values_content1": [
      'We all have the same amount of time in a day, but some people seem to get more done. What are they doing?',
      "Unlike other apps, this helps you track long-term tasks and how often you do them."
    ],
    "welcome_values_title2": [
      "Feeling overwhelmed?",
      "Feeling overwhelmed?"
    ],
    "welcome_values_content2": [
      "'Why not put it all in a nice ignorable list? You can always come back to it later.'",
      "Even doing just a bit every day helps. This doesn’t track how much you’ve done, only if you’ve done it."
    ],
    "welcome_values_title3": [
      "Get dopamine without doing anything!",
      "Track your stats"
    ],
    "welcome_values_content3": [
      "It's not fair that other apps only reward you for doing things. We give you rewards just for looking at your list!",
      "Motivate yourself by looking back at your progress and how much you’ve done."
    ],
    "welcome_screenshots_title": "Screenshots",
    "welcome_faq_title": 'Got any questions? Need help?',
    "welcome_faq_alert": "Whoops, you're on your own!",
    "welcome_faq_response": "This is not a business and there is no customer support.\nYou're on your own.",
    "welcome_faqs": [
      [
        {
          q: "Is this real? / Is this a joke?",
          a: "Yes."
        },
        {
          q: "How does it work?",
          a: "1. You create tasks\n2. Do not do them\n3. ?????\n4. Profit"
        },
        {
          q: "Is it secure?",
          a: "Haha. No." 
        },
        {
          q: "Why?",
          a: "I made it for myself after procrastinating on a project for too long and I wanted to keep track of my procrastination." 
        },
        {
          q: "Can I use it?",
          a: "At your own risk." 
        },
        {
          q: "Can you add feature X?",
          a: "Drop me a message and I'll see what I can do." 
        },
        {
          q: "How do I contact you?",
          a: "Uh.... @ me on twitter? (if it still exists)" 
        },
        {
          q: "Wow this is so amazing how do I give you money?",
          a: "I have no plans to monetise this, but if it gets used, I might.\nYou can also <a href='https://ko-fi.com/cjx3711'>donate to my food fund here.</a>"
        }
      ],
      [
        {
          q: "Is this real? / Is this a joke?",
          a: "Yes."
        },
        {
          q: "How does it work?",
          a: "You create tasks, you mark the tasks as done when you do them, you see nice graphs on your progress."
        },
        {
          q: "Is it secure?",
          a: "If you use the offline mode, no personal data is transmitted to the servers.\n\nIf you want to use it online. I store the data on my personal server and provide no guarantees about its security or uptime.\n(Not that it exists yet)" 
        },
        {
          q: "Why?",
          a: "I have not found any app that allows tracking long-term and semi-regular tasks well. So I built this." 
        },
        {
          q: "Can I use it?",
          a: "At your own risk." 
        },
        {
          q: "Can you add feature X?",
          a: "Drop me a message and I'll see what I can do." 
        },
        {
          q: "How do I contact you?",
          a: "Uh.... @ me on twitter? (if it still exists)" 
        },
        {
          q: "Can I contribute?",
          a: "I have no plans to monetise this, but if it gets used, I might.\nYou can also <a href='https://ko-fi.com/cjx3711'>donate to my food fund here.</a>"
        }
      ]
    ]
  },
}

const getAppText = (key: string, procrastinateMode:boolean = true):any => {
  if (!allAppText["en"][key]) {
    throw new Error(`App text key ${key} not found.`);
  }

  // Check if allAppText["en"][key] is a string
  if(typeof allAppText["en"][key] === "string") {
    return allAppText["en"][key];
  } else {
    // It should be an array of 2 strings.
    return allAppText["en"][key][procrastinateMode ? 0 : 1]
  }
}

export default getAppText;