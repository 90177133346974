import ArchiveIcon from '@mui/icons-material/Archive';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FlakyIcon from '@mui/icons-material/Flaky';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SnoozeIcon from '@mui/icons-material/Snooze';
import { CardHeader, Chip, IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { formatDistanceToNow, formatDistanceToNowStrict } from "date-fns";
import React, { Fragment, useState } from "react";
import { getFavouriteDoitIds, toggleFavoriteDoit } from '../helpers/Database';
import { isDoitCompleted, isDoitSnoozed } from '../helpers/DoitHelpers';
import { Doit, DoitWithMeta, InteractionType } from "../pages/AppContainer";

export interface DoitListCardsProps {
  doits: Doit[];
  currentDoitId: string | null;
  handleSettingsClick: (event: React.MouseEvent<HTMLElement>, doit: Doit) => void;
  handleInteraction: (doit: Doit, interaction: InteractionType) => void;
}

const DoitListCards = (props: DoitListCardsProps) => {
  const { doits, currentDoitId, handleSettingsClick, handleInteraction } = props;
  const haveCurrentDoit = currentDoitId !== null;

  const [ favourites, setFavourites ] = useState(getFavouriteDoitIds());
  const handleFavouriteClick = (doit: Doit) => {
    setFavourites(toggleFavoriteDoit(doit.localId));
  }

  const formatDistanceShort = (snoozeTill: number) => {
    return formatDistanceToNowStrict(new Date(snoozeTill), {addSuffix: true}).replace('hours', 'hr').replace('hour', 'hr').replace('minutes', 'min').replace('minute', 'min').replace('seconds', 'sec').replace('second', 'sec').replace('days', 'd').replace('day', 'd').replace('months', 'mo').replace('month', 'mo').replace('years', 'yr').replace('rear', 'yr');
  }

   // Map the favourites then move facourites to the top of the list
  const sortedDoits:DoitWithMeta[] = doits.map ((doit: Doit) => {
    return {
      favourite: favourites.includes(doit.localId),
      ...doit
    }
  }).sort((a, b) => {
    if (a.favourite && !b.favourite) return -1;
    if (!a.favourite && b.favourite) return 1;
    return 0;
  })

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', bgcolor: 'background.paper' }}>
      { sortedDoits.map((doit:DoitWithMeta) => (
        <Card key={doit.localId} sx={{ mx: 2, mb: 2, width: 300, textAlign: 'left' }}>
          <CardHeader
            action={
              <Box>
                {
                  doit.status !== "completed" && <IconButton
                    aria-label="settings"
                    onClick={(event) => handleSettingsClick(event, doit)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
              </Box>
            }
            title={
              <Box sx={{display: 'flex', alignContent: 'middle'}}>
                <IconButton
                  aria-label="favourite"
                  size="small"
                  onClick={() => handleFavouriteClick(doit)}
                  sx={{ ml: -1, mt: -0.8, mr: 0.5}} >
                  { doit.favourite ? <FavoriteIcon color="primary"/> : <FavoriteBorderIcon /> }
                </IconButton>
                { doit.type === 'once' && <LooksOneOutlinedIcon sx={{ fontSize: '1.2em', mr: 0.5 }} /> }
                <Typography variant="inherit" component="div"> {doit.name} </Typography>
              </Box>
            }
            sx={{ pb: 1 }}
          />
          <CardContent sx={{ pt: 0 }}>
            { doit.description &&
              <Typography variant="body2" color="text.secondary">
                {doit.description.split("\n").map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </Typography>
            }
            {
              doit.tags && doit.tags.length > 0 &&
              <Box sx={{mt:2}}>
                {doit.tags.map((tag) => (
                  <Chip sx={{ mr:0.5 }} key={tag} label={tag} size="small" variant="outlined" />
                ))}
              </Box>
            }
            {
              doit.localId === currentDoitId &&
              <Box sx={{mt:2}}>
                <Chip icon={<FlakyIcon />} label={'Doing Now'} size="small" color="primary" variant="outlined" />
              </Box>
            }
            {
              isDoitCompleted(doit) &&
              <Box sx={{mt:2}}>
                <Chip icon={<ArchiveIcon />} label={'archived'} size="small" sx={{color: 'green'}} variant="outlined" />
              </Box>
            }
            {
              !isDoitCompleted(doit) && isDoitSnoozed(doit) &&
              <Box sx={{mt:2}}>
                <Chip icon={<SnoozeIcon />} label={`${formatDistanceToNow(new Date(doit.snoozeTill!))} from now`} size="small" color="info" variant="outlined" />
              </Box>
            }
          </CardContent>
          <CardActions sx={{position: 'relative'}}>
            {
              !isDoitCompleted(doit) && (
                isDoitSnoozed(doit) ? (
                  <Button size="small" onClick={() => handleInteraction(doit, 'unsnooze')} color="info">Unsnooze</Button>
                ) : (
                  !haveCurrentDoit &&
                  <Button size="small" onClick={() => handleInteraction(doit, 'doit')}>Start</Button>
                )
              )
            }
            { doit.lastInteractionAt &&
              <Typography variant="body2" color="text.secondary" sx={{textAlign: 'left', position: 'absolute', right: 5, }}>
                Last interaction: {formatDistanceShort(doit.lastInteractionAt!)}
              </Typography>
            }
          </CardActions>
        </Card>
      )) }
    </Box>
  );
};

export default DoitListCards;