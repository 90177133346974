import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Modal, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { useEffect, useState } from "react";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
const boxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const images = [
  {
    label: 'Step 1: Add some doits',
    paragraph: [
      "Click the '+' button to add a doit",
      "A doit is not a simple task, it is better suited for long term goals",
      "The app works better the more doits you have",
    ],
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Step 2: Do, or not do',
    paragraph: [
      "On the 'What to do' page, swipe doits like you would on Tinder",
      "You can only do one doit at a time",
    ],
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Step 3: View the list',
    paragraph: [
      "You can also view it as a list on the 'All Doits' page",
    ],
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  },
  {
    label: 'Step 4: View Stats',
    paragraph: [
      "On the 'What I Did' page, you can see how much you've done over time",
      "Collect achievements and enjoy the high scores (coming soon)"
    ],
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];

const FirstTimeModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleModalClose = () => {
    setShowModal(false);
    localStorage.setItem("firstTimeDone", "true");
  };
  
  useEffect (() => {
    const firstTimeDone = localStorage.getItem("firstTimeDone");
    if (!firstTimeDone) {
      setShowModal(true);
    }
  }, []);
  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Quickstart Guide
        </Typography>
        <SwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
        {images.map((step, index) => (
          <div key={step.label}>
            
            <Typography variant="h5" sx={{ my: 3 }}>{step.label}</Typography>
            {step.paragraph.map((paragraph) => (
              <Typography key={paragraph} variant="body1" sx={{my:1}}>{paragraph}</Typography>
            ))}
            <Box sx={{ my: 2 }} />
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
            <Typography variant="caption" sx={{ my: 1 }}>
              This is a placeholder image
            </Typography>
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
           activeStep === maxSteps - 1 ?
            <Button
              onClick={handleModalClose}>
                Finish
              </Button> :
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next <KeyboardArrowRight />
            </Button>
          }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
      </Box>
    </Modal>
  )
}

export default FirstTimeModal;
