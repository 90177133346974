import { addDays, formatISO9075 } from "date-fns";

export interface UserStats {
  streaks: {
    didit: number;
    later: number;
    completed: number;
    login: number;
  };
  highestStreak: {
    didit: number;
    later: number;
    completed: number;
    login: number;
  };
  totals: {
    didit: number;
    later: number;
    completed: number;
    created: number;
    login: number;
    deleted: number;
  };
  totalDays: {
    didit: number;
    later: number;
    completed: number;
    login: number;
  };
  lastDate: {
    didit: string | null;
    later: string | null;
    completed: string | null;
    login: string | null;
  };
  points: number;
  accountCreatedDate: string;
}


export const getStats = (): UserStats => {
  let stats = localStorage.getItem("stats");
  if (stats) {
    return JSON.parse(stats);
  }
  return {
    streaks: {
      didit: 0,
      later: 0,
      completed: 0,
      login: 0,
    },
    highestStreak: {
      didit: 0,
      later: 0,
      completed: 0,
      login: 0,
    },
    totals: {
      completed: 0,
      created: 0,
      didit: 0,
      later: 0,
      login: 0,
      deleted: 0,
    },
    totalDays: {
      didit: 0,
      later: 0,
      completed: 0,
      login: 0,
    },
    lastDate: {
      didit: null,
      later: null,
      completed: null,
      login: null,
    },
    points: 0,
    accountCreatedDate: formatISO9075(new Date(), { representation: "date" }),
  };
}

export const getStatLoginDays = (): string[] => {
  const loginDays = localStorage.getItem("statLoginDays");
  if (loginDays) {
    return JSON.parse(loginDays);
  }
  return [];
};

export const saveStats = (stats: UserStats) => {
  console.log("Saving stats", stats);
  localStorage.setItem("stats", JSON.stringify(stats));
  localStorage.setItem("statsDirty", "true");
};

export const saveStatLoginDays = (loginDays: string[]) => {
  console.log("Saving login days", loginDays);
  localStorage.setItem("statLoginDays", JSON.stringify(loginDays));
  localStorage.setItem("statsLoginDaysDirty", "true");
};

// Add a 
const addStreakableStat = (stats: any, stat: string) => {
  stats.totals[stat] += 1;
  if (!stats.lastDate[stat]) {
    stats.lastDate[stat] = formatISO9075(new Date(), { representation: "date" });
    stats.totalDays[stat] += 1;
    stats.streaks[stat] = 1;
  } else {
    const todayDate = formatISO9075(new Date(), { representation: "date" });
    const yesterdayDate = formatISO9075(addDays(new Date(), -1), { representation: "date" });
    if (stats.lastDate[stat] !== todayDate) {
      stats.totalDays[stat] += 1;
    }
    if (stats.lastDate[stat] === yesterdayDate) {
      stats.streaks[stat] += 1;
    } else {
      stats.streaks[stat] = 1;
    }
    stats.highestStreak[stat] = Math.max(stats.streaks[stat], stats.highestStreak[stat]);
    stats.lastDate[stat] = todayDate;
  }
}

// Checks if a streak is broken by checking if the last date is not in today or yesterday
// returns true if stats were updated
const checkStreakableStat = (stats: any, stat: string): boolean => {
  const todayDate = formatISO9075(new Date(), { representation: "date" });
  const yesterdayDate = formatISO9075(addDays(new Date(), -1), { representation: "date" });
  if (stats.streaks[stat] > 0 && ![todayDate, yesterdayDate.includes(stats.lastDate[stat])]) {
    stats.streaks[stat] = 0;
    return true;
  }
  return false;
}

// Runs on app startup
export const checkAllStreakableStats = () => {
  const stats = getStats();
  let updated = false;
  updated = checkStreakableStat(stats, "didit") || updated;
  updated = checkStreakableStat(stats, "later") || updated;
  updated = checkStreakableStat(stats, "completed") || updated;
  if (updated) {
    saveStats(stats);
  }
}

export const addDiditStat = () => {
  const stats = getStats();
  addStreakableStat(stats, "didit");
  saveStats(stats);
}

export const addLaterStat = () => {
  const stats = getStats();
  addStreakableStat(stats, "later");
  saveStats(stats);
}

export const addCompletedStat = () => {
  const stats = getStats();
  addStreakableStat(stats, "completed");
  saveStats(stats);
}

// Gets called on every app context load
export const addLoginStat = () => {
  let opened = true;
  const sessionOpened = sessionStorage.getItem("opened");
  if (!sessionOpened) {
    sessionStorage.setItem("opened", "true");
    opened = false;
  }

  // Add the date to the login days if it is a different day
  const lastLogin = localStorage.getItem("lastLogin");
  const todayDate = formatISO9075(new Date(), { representation: "date" });
  if (lastLogin !== todayDate) {
    localStorage.setItem("lastLogin", todayDate);
    // Push the day
    const loginDays = getStatLoginDays();
    loginDays.push(todayDate);
    saveStatLoginDays(loginDays);
  }

  // If the app was not opened today, add a login stat
  if (!opened || lastLogin !== todayDate) {
    const stats = getStats();
    addStreakableStat(stats, "login");
    saveStats(stats);
  }
}

export const addCreatedStat = () => {
  const stats = getStats();
  stats.totals.created += 1;
  saveStats(stats);
}

export const addDeletedStat = () => {
  const stats = getStats();
  stats.totals.deleted += 1;
  saveStats(stats);
}
