import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { addInteraction, removeCurrentDoit, snoozeDoit } from "../helpers/Database";
import { Doit, InteractionType } from '../pages/AppContainer';
import React, { Fragment } from "react";
import AppContext from '../helpers/AppContext';


export interface CurrentDoitProps {
  currentDoit: Doit | null
}
const CurrentDoit = (props: CurrentDoitProps) => {
  const { currentDoit } = props;
  const { setUpdate } = React.useContext(AppContext);

  if (!currentDoit) {
    return ( <></> );
  }

  const handleInteraction = async (interactionType: InteractionType) => {
    addInteraction(currentDoit, interactionType);
    switch (interactionType) {
      case 'didit':
        await snoozeDoit(currentDoit, 12);
        await removeCurrentDoit();
        setUpdate((update) => !update);
        break;
      case 'didntit':
        await removeCurrentDoit();
        setUpdate((update) => !update);
        break;
    }
  };
  
  return (
    <Card sx={{ width: "95%", maxWidth: 700 }}>
      <CardContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" color="text.secondary" gutterBottom>
              CURRENTLY WORKING ON:
            </Typography>
            <Typography variant="h4">
              {currentDoit.name}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", textAlign: "left", mt: 1, mx: 'auto', width: 300}}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<DirectionsRunIcon />}
                sx={{ mx: 0.5}}
                size="small"
                onClick={(event) => handleInteraction('didit')}
                >
                Did it
              </Button>
              <Button
                variant="contained"
                fullWidth
                startIcon={<LocalHotelIcon />}
                sx={{ mx: 0.5 }}
                size="small"
                onClick={(event) => handleInteraction('didntit')}
                color="error">
                nah
              </Button>
            </Box>
        </Box>
        
      </CardContent>
    </Card>
  );
};

export default CurrentDoit;

function setUpdate(arg0: (update: any) => boolean) {
  throw new Error('Function not implemented.');
}
