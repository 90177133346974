import ArchiveIcon from '@mui/icons-material/Archive';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FlakyIcon from '@mui/icons-material/Flaky';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SnoozeIcon from '@mui/icons-material/Snooze';
import { Box, Button, Chip, Collapse, IconButton, ListItem } from "@mui/material";
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import React from "react";
import { Doit, DoitWithMeta, InteractionType } from "../pages/AppContainer";
import Typography from './Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

export interface DoitListTableRowProps {
  doit: DoitWithMeta;
  currentDoitId: string | null;
  handleFavouriteClick: (doit: Doit) => void;
  handleSettingsClick: (event: React.MouseEvent<HTMLElement>, doit: Doit) => void;
  handleInteraction: (doit: Doit, interaction: InteractionType) => void;
}

const DoitListTableRow = (props: DoitListTableRowProps) => {
  const { doit, currentDoitId, handleSettingsClick, handleInteraction, handleFavouriteClick } = props;
  const haveCurrentDoit = currentDoitId !== null;
  const [open, setOpen] = React.useState(false);

  const formatDistanceShorter = (snoozeTill: number) => {
    return formatDistanceToNowStrict(new Date(snoozeTill)).replace('hours', 'h').replace('hour', 'h').replace('minutes', 'm').replace('minute', 'm').replace('seconds', 's').replace('second', 's').replace('days', 'd').replace('day', 'd').replace('months', 'mo').replace('month', 'mo').replace('years', 'y').replace('year', 'y').replace(' ', '');
  }

  return (
    <ListItem sx={{ border: '#CCCCCC 1px solid', p: 0}} >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <Button
          color="inherit"
          fullWidth
          sx={{ textTransform: 'none', fontWeight: 'inherit', textAlign: 'left', alignItems: 'left', justifyContent: 'flex-start', width: '100%' }}
          onClick={() => setOpen(!open)}
          >
          {open ? <ExpandLess /> : <ExpandMore />}
          <Typography sx={{ ml: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: open ? 'wrap' : 'nowrap', width: '100%', alignContent: 'baseline'}}>
            { doit.type === 'once' && <LooksOneOutlinedIcon sx={{ fontSize: '1.2em', mr: 0.5, mb:'-3px' }} /> }
            {doit.name}
          </Typography>
          
          { !open && doit.tags && doit.tags.length > 0 &&
            <Chip
              sx={{ ml: 0.5 }}
              size="small" variant="outlined"
              label={doit.tags[0] + (doit.tags.length>1?' +'+(doit.tags.length-1):'')} />
          }
          {
            doit.localId === currentDoitId &&
              <Chip icon={<FlakyIcon />} label={'Doing'} size="small" color="primary" variant="outlined" />
          }
          {
            !open && doit.status !== "completed" && doit.snoozeTill && doit.snoozeTill > Date.now() &&
              <Chip icon={<SnoozeIcon />} label={formatDistanceShorter(doit.snoozeTill)} size="small" color="info" variant="outlined" />
          }
        </Button>
        <IconButton
          aria-label="favourite"
          size="small"
          onClick={() => handleFavouriteClick(doit)}
        >
          { doit.favourite ? <FavoriteIcon color="primary"/> : <FavoriteBorderIcon /> }
        </IconButton>
        {
          doit.status !== "completed" && <IconButton
            aria-label="settings"
            onClick={(event) => handleSettingsClick(event, doit)}
          >
            <MoreVertIcon />
          </IconButton>
        }
      </Box>
      <Collapse in={open} unmountOnExit sx={{ width: '100%', pl: 6, pb: 2}}>
        <Box sx={{ textAlign: 'left'}}>
          { doit.description ?
            <Typography variant="body2">{doit.description}</Typography>
            :
            <Typography variant="caption" color="text.secondary" sx={{ opacity: 0.7 }}>
              <em>No description</em>
            </Typography>
          }
        </Box>
        {
          doit.tags && doit.tags.length > 0 &&
          <Box sx={{mt:2}}>
            {doit.tags.map((tag) => (
              <Chip sx={{ mr:0.5 }} key={tag} label={tag} size="small" variant="outlined" />
            ))}
          </Box>
        }
        <Box>
        {
          doit.status === "completed" &&
            <Chip icon={<ArchiveIcon />} label={'archived'} size="small" variant="outlined" />
        }
        {
          doit.status !== "completed" && doit.snoozeTill && doit.snoozeTill > Date.now() &&
            <Chip icon={<SnoozeIcon />} label={`${formatDistanceToNow(new Date(doit.snoozeTill))} from now`} size="small" color="info" variant="outlined" />
        }
        {
          doit.status !== "completed" && (
            doit.snoozeTill && doit.snoozeTill > Date.now() ? (
              <Button sx={{mt:1}} size="small" onClick={() => handleInteraction(doit, 'unsnooze')} color="info">Unsnooze</Button>
            ) : (
              !haveCurrentDoit &&
              <Button sx={{mt:1}} size="small" onClick={() => handleInteraction(doit, 'doit')}>Start</Button>
            )
          )
        }
        </Box>
        { doit.lastInteractionAt &&
          <Typography variant="body2" color="text.secondary" sx={{textAlign: 'left', position: 'absolute', right: 5, bottom: 5}}>
            Last interaction: {formatDistanceToNow(new Date(doit.lastInteractionAt!), { addSuffix: true })}
          </Typography>
        }
      </Collapse>
      </Box>
    </ListItem>
  );
}

export default DoitListTableRow;