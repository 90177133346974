import { isDoitSnoozed } from './DoitHelpers';
import { Doit, DoitLite, Interaction, InteractionType } from '../pages/AppContainer';
import Localbase from 'localbase';
import { v4 } from 'uuid';
import { addHours, formatISO9075, getTime } from 'date-fns';
import { flatten } from 'lodash';
import { addCompletedStat, addCreatedStat, addDeletedStat, addLaterStat, addDiditStat } from './Stats';
import { getAppSettings } from '../pages/AppSettings';


const db = new Localbase('db');


export const getAvailableTags = (): string[] => {
  const availableTags: string[] = JSON.parse(localStorage.getItem("availableTags") || "[]");
  return availableTags;
}

export const addNewTags = async (tags: string[]) => {
  const availableTags: string[] = JSON.parse(localStorage.getItem("availableTags") || "[]");
  // Get list of new tags
  const newTags = tags.filter((tag) => !availableTags.includes(tag));
  console.log("New Tags: ", newTags);
  // Send new tags to the server
  // TODO

  // Write new tags to localStorage
  localStorage.setItem("availableTags", JSON.stringify([...availableTags, ...newTags]));
}

export const addDoit = async (doit: DoitLite) => {
  const doitComplete = {
    synced: false,
    localId: v4().replace(/-/g, ''),
    remoteId: null,
    status: 'active',
    createdAt: Date.now(),
    updatedAt: Date.now(),
    snoozeTill: null,
    lastInteractionAt: Date.now(),
    lastDoneAt: null,
    lastSkippedAt: null,
    ...doit
  };
  addCreatedStat();
  await db.collection('doits').add(doitComplete);
}

export const deleteDoit = async (doit: Doit) => {
  if (!window.confirm("Are you sure you want to delete this Doit?\nThis action cannot be undone.")) {
    return;
  }
  const currentDoit = getCurrentDoit();
  if (currentDoit && currentDoit.localId === doit.localId) {
    alert("You cannot delete a Doit that is currently in progress.\nPlease complete or skip the Doit first.");
    return;
  }
  console.log("Deleting doit: ", doit);
  // Get doit from localbase
  const d = await db.collection('doits').doc({ localId: doit.localId }).get();
  console.log("Got doit: ", d);
    // Add to deleted list
  await db.collection('deletedDoits').add(d);
  console.log("Added to deletedDoits");
  await db.collection('doits').doc({ localId: d.localId }).delete()
  console.log("Deleted doit");
  addDeletedStat();
}

export const updateDoit = async (doitLocalId:string, doit: DoitLite) => {
  console.log("Updating doit: ", doitLocalId, doit);
  await db.collection('doits').doc({ localId: doitLocalId }).update(doit);
}

export const getDoits = async (): Promise<Doit[]> => {
  const doits = await db.collection('doits').get();
  return flatten([doits]); // This wraps it in an array if there is only one item
}

export const getDoit = async (localId: string): Promise<Doit> => {
  const doit = await db.collection('doits').doc({ localId }).get();
  return doit;
}

export const removeCurrentDoit = async () => {
  await localStorage.removeItem("currentDoit");
}

// Sets the current doit to a localStorage variable
export const setCurrentDoit = async (doit: Doit) => {
  await localStorage.setItem("currentDoit", JSON.stringify(doit));
}

// Snoozes a doit, but does not record an interaction
export const snoozeDoit = async (doit: Doit, hours: number) => {
  console.log("Snooze");
  let snoozeTillTime;

  if (isDoitSnoozed(doit)) {
    console.log("Doit is already snoozed, adding hours to existing snooze time");
    snoozeTillTime = addHours(new Date(doit.snoozeTill!), hours);
  } else {
    console.log("Doit is not snoozed, setting snooze time to now + hours");
    snoozeTillTime = addHours(new Date(), hours)
  }
  // Update doit in localbase
  await db.collection('doits').doc({ localId: doit.localId }).update({
    snoozeTill: getTime(snoozeTillTime),
    updatedAt: Date.now()
  });
}

export const unsnoozeDoit = async (doit: Doit) => {
  // Update doit in localbase
  await db.collection('doits').doc({ localId: doit.localId }).update({
    snoozeTill: null,
    updatedAt: Date.now()
  });
}

// Records a doit as completed, but does not record an interaction
export const completeDoit = async (doit: Doit, bypassCheck: boolean = false) => {
  if (!bypassCheck) {
    if (!window.confirm("Are you sure you want to archive this?\nYou cannot unarchive it for now.\nOnly archive it if you do not want to see it again.")) {
      return;
    }
  }

  console.log("Complete");
  addCompletedStat();
  // Update doit in localbase
  await db.collection('doits').doc({ localId: doit.localId }).update({
    status: 'completed',
    lastDoneAt: Date.now(),
    updatedAt: Date.now()
  });
}

export const getCurrentDoit = ():Doit|null => {
  const doitString = localStorage.getItem("currentDoit");
  if (doitString) {
    return JSON.parse(doitString);
  }
  return null;
}

export const addInteraction = async (doit: Doit, type: InteractionType, backdateTo?: string) => {
  const doitLocalId = doit.localId;
  const doitName = doit.name;
  console.log("Adding interaction: ", type, doitLocalId, doitName, backdateTo);

  const todayDate = formatISO9075(new Date(), {representation: 'date'});
  const forDate = backdateTo ? backdateTo : todayDate;
  // Check if the interaction already exists for today
  const existingInteraction = 
    await db.collection('interactions')
      .doc({ doitLocalId: doitLocalId, forDate, type }).get();

  if (existingInteraction) {
    console.log("Interaction already exists for today, updating count");
    // Update the count
    await db.collection('interactions').doc({ localId: existingInteraction.localId }).update({
      count: existingInteraction.count + 1,
      updatedAt: Date.now(),
      synced: false
    });
  } else {
    const interaction: Interaction = {
      localId: v4().replace(/-/g, ''),
      remoteId: null,
      doitLocalId: doitLocalId,
      doitName: doitName,
      count: 1,
      type: type,
      forDate: forDate,
      createdDate: todayDate,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      synced: false
    }
    // Update Stats
    if (type === "didit") {
      addDiditStat();
    } else if (['later', 'didntit'].includes(type)) {
      addLaterStat();
    } else if (type === "completed") {
      addCompletedStat();
    }
    await db.collection('interactions').add(interaction);
  }
  
  // Update doit last interaction
  await db.collection('doits').doc({ localId: doitLocalId }).update({
    lastInteractionAt: Date.now(),
    updatedAt: Date.now()
  });
}

export const getRecentInteractions = async (count:number = 500): Promise<Interaction[]> => {
  const interactions = await db.collection('interactions').orderBy('forDate', 'desc').limit(count).get();
  return flatten([interactions]); // This wraps it in an array if there is only one item
}

export const getInteractions = async (): Promise<Interaction[]> => {
  // const allInteractions:Interaction[] = flatten([await db.collection('interactions').get()]);
  // allInteractions.forEach(i => {
  //   i.forDate = i.createdDate;
  //   db.collection('interactions').doc({ localId: i.localId }).update(i);
  // });

  const interactions = await db.collection('interactions').orderBy('forDate', 'desc').get();
  return flatten([interactions]); // This wraps it in an array if there is only one item
}

export const dropWholeDatabase = async () => {
  await db.delete();
  localStorage.clear();
}

export const setDoits = async (doits: Doit[]) => {
  for (let i = 0; i < doits.length; i++) {
    const doit = doits[i];
    await db.collection('doits').add(doit);
    console.log("Importing", doit, doit);
  }
}

export const setInteractions = async (interactions: Interaction[]) => {
  for (let i = 0; i < interactions.length; i++) {
    const interaction = interactions[i];
    if (!interaction.count) interaction.count = 1; // For upgrading the database where count didn't exist yet
    const { doitLocalId, type, forDate, count } = interaction;
    const existingInteraction = 
      await db.collection('interactions')
        .doc({ doitLocalId: doitLocalId, forDate, type }).get();
    if (existingInteraction) {
      await db.collection('interactions').doc({ localId: existingInteraction.localId }).update({
        count: existingInteraction.count + count,
        updatedAt: Date.now(),
        synced: false
      });
    } else {
      await db.collection('interactions').add(interaction);
    }
  }
}


export const checkDatabaseVersion = async () => {
  const currentDatabaseVersion = 1;
  const databaseVersion = localStorage.getItem("databaseVersion");
  if (databaseVersion == null) {
    console.log("Database version not found, creating new database");
    localStorage.setItem("databaseVersion", `${currentDatabaseVersion}`);
  } else if (parseInt(databaseVersion) < currentDatabaseVersion) {
    console.log("Database version too old, requires an upgrade");
    alert("Your database version is too old, it needs to be upgraded")
    localStorage.setItem("databaseVersion", `${currentDatabaseVersion}`);
  } else if (parseInt(databaseVersion) > currentDatabaseVersion) {
    console.log("Database version too new, requires an downgrade");
    alert("Your database version is too new, how the heck did you achieve this?");
  }
}

// Create a singleton favourite doits array
let favouriteDoits:string[] = [];
// Gets a list of the favourite doit ids
export const getFavouriteDoitIds = (): string[] => {
  const faveDoitString = localStorage.getItem("doitFaves");
  if (faveDoitString) {
    favouriteDoits = JSON.parse(faveDoitString);
  }
  return favouriteDoits;
}

// Toggle a doit as a favourite and returns the new favourite list
// Saves the new favourite list to local storage
export const toggleFavoriteDoit = (doitLocalId: string): string[] => {
  const favouriteDoitIds = getFavouriteDoitIds();
  const index = favouriteDoitIds.indexOf(doitLocalId);
  if (index > -1) {
    favouriteDoitIds.splice(index, 1);
  } else {
    favouriteDoitIds.push(doitLocalId);
  }
  localStorage.setItem("doitFaves", JSON.stringify(favouriteDoitIds));
  favouriteDoits = favouriteDoitIds;
  return favouriteDoits
}

// Create a singleton favourite tags array
let favouriteTags:string[] = [];
// Gets a list of the favourite tag ids
export const getFavouriteTagIds = (): string[] => {
  const faveTagString = localStorage.getItem("tagFaves");
  if (faveTagString) {
    favouriteTags = JSON.parse(faveTagString);
  }
  return favouriteTags;
}

// Toggle a tag as a favourite and returns the new favourite list
// Saves the new favourite list to local storage
export const toggleFavoriteTag = (tagLocalId: string): string[] => {
  const favouriteTagIds = getFavouriteTagIds();
  const index = favouriteTagIds.indexOf(tagLocalId);
  if (index > -1) {
    favouriteTagIds.splice(index, 1);
  } else {
    favouriteTagIds.push(tagLocalId);
  }
  localStorage.setItem("tagFaves", JSON.stringify(favouriteTagIds));
  favouriteTags = favouriteTagIds;
  return favouriteTags
}