import { Container, Link, Typography } from "@mui/material";

const AppCopyright = () => {
  return (
    <Typography
      sx={{
        p: 0.5,
        bottom: '56px',
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        background: 'white',
        borderTopRightRadius: '7px',
        opacity: 0.65,
        zIndex:2000
      }}
    >
      <Container sx={{ my: 0, alignItems: 'left', p: 0, width: 'auto' }}>
        <Typography variant="body2" color="black" align="left">
          {'©'}
          <Link color="inherit" href="https://doitlater.app/">
            Doitlater
          </Link>
          {' '}
          {new Date().getFullYear()}
          {' from '}
          <Link color="inherit" href="https://github.com/cjx3711">
            @cjx3711
          </Link>
        </Typography>
      </Container>
    </Typography>
  );
}

export default AppCopyright;