import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardActionArea, CardHeader, Chip, Divider, IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { formatDistanceToNow } from 'date-fns';
import React, { Fragment } from "react";
import AppContext from "../helpers/AppContext";
import { addInteraction, completeDoit, deleteDoit, removeCurrentDoit, setCurrentDoit, snoozeDoit } from "../helpers/Database";
import { Doit, InteractionType } from "../pages/AppContainer";
import { getAppSettings } from '../pages/AppSettings';
import DoitContextMenu, { DoitContextMenuOptions } from './DoitContextMenu';
import ExpandMore from './ExpandMore';
import LaterButton from './LaterButton';

export interface DoitSuggestionProps {
  doit: Doit;
  nextDoitCallback?: (() => void);
  disabled?: boolean;
  doing?: boolean;
  sx?: any;
}

const DoitSuggestion = (props: DoitSuggestionProps) => {
  const { setUpdate, setEditDoit, setBackdateDoit, setSnackbar } = React.useContext(AppContext);
  const { doing, doit, nextDoitCallback, sx, disabled } = props;
  const [cardFlipped, setCardFlipped] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const appSettings = getAppSettings(); 

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>, doit: Doit) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const optionClickCallback = async (type: DoitContextMenuOptions, hours?:number) => {
    switch (type) {
      case 'edit':
        if (doit) setEditDoit(doit.localId);
        break;
      case 'backdatedidit':
        if (doit) setBackdateDoit({ localId: doit.localId, action: 'didit' });
        break;
      case 'backdatelater':
        if (doit) setBackdateDoit({ localId: doit.localId, action: 'later' });
        break;
      case 'snooze':
        await snoozeDoit(doit, hours!);
        await addInteraction(doit, 'snooze');
        if (nextDoitCallback) nextDoitCallback();
        break;
      case 'didit':
        await addInteraction(doit, 'didit');
        await snoozeDoit(doit, appSettings.doitCompletionSnooze);
        if (doit.type === 'once') {
          await completeDoit(doit, true);
          setSnackbar({ open: true, message: `Doit completed and archived`, severity: "info" });
        }
        if (nextDoitCallback) nextDoitCallback();
        break;
      case 'complete':
        await completeDoit(doit);
        setUpdate((update) => !update);
        break;
      case 'delete':
        await deleteDoit(doit);
        setUpdate((update) => !update);
        break;
    }
  }

  const handleInteraction = async (interactionType: InteractionType) => {
    console.log("Interaction Type: ", interactionType);
    // Create Interaction
    await addInteraction(doit, interactionType);
    switch (interactionType) {
      case 'doit':
        await setCurrentDoit(doit);
        setUpdate((update) => !update);
        break;
      case 'didit':
        await snoozeDoit(doit, appSettings.doitCompletionSnooze);
        await removeCurrentDoit();
        if (doit.type === 'once') {
          await completeDoit(doit, true);
          setSnackbar({ open: true, message: `Doit completed and archived`, severity: "info" });
        }
        setUpdate((update) => !update);
        break;
      case 'didntit':
        await removeCurrentDoit();
        setUpdate((update) => !update);
        break;
      case 'later':
        if (nextDoitCallback) nextDoitCallback();
        break;
    }
  }

  return (
    <Box sx={{ ...sx, transition: 'all 300ms'}}>
      <DoitContextMenu anchorEl={anchorEl} open={open} handleClose={handleSettingsClose} optionClickCallback={optionClickCallback} doing={doing}/>
      { doit &&
        <Card sx={{ position: 'relative', width: {xs: 350, sm: 430, md: 500}, textAlign: 'center' }}>
          { doit.type === 'once' && <LooksOneOutlinedIcon sx={{ position: 'absolute', fontSize: '1.8em', top: 15, left: 15 }}/> }
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                onClick={(event) => handleSettingsClick(event, doit)}
              >
                <MoreVertIcon />
              </IconButton>
            }
            sx={{ pb: 1 }}
          />
          <CardActionArea onClick={() => {setCardFlipped(!cardFlipped)}}>
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ display: 'flex', minHeight: {xs: 180, sm: 220, md: 250}, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <Typography variant="h3" component="div">
                  {doit.name}
                </Typography>
                <Collapse in={cardFlipped} timeout="auto" unmountOnExit>
                  <Divider sx={{my:1}}/>
                  { doit.description ?
                    <Typography variant="body1" color="text.secondary">
                      {doit.description.split("\n").map((line, index) => (
                        <Fragment key={index}>
                          {line}
                          <br />
                        </Fragment>
                      ))}
                    </Typography>
                    :
                    <Typography variant="caption" color="text.secondary" sx={{ opacity: 0.7 }}>
                      <em>No description</em>
                    </Typography>
                  }
                  {
                    doit.tags && doit.tags.length > 0 &&
                    <Box sx={{my:2}}>
                      {doit.tags.map((tag) => (
                        <Chip sx={{ mx:1 }} key={tag} label={tag} size="small" variant="outlined" />
                      ))}
                    </Box>
                  }
                  { doit.lastInteractionAt &&
                    <Typography variant="body2" color="text.secondary" sx={{textAlign: 'left', position: 'absolute', right: 10, bottom: 5}}>
                      Last interaction: {formatDistanceToNow(new Date(doit.lastInteractionAt!), { addSuffix: true })}
                    </Typography>
                  }
                </Collapse>
                <ExpandMore
                  expand={cardFlipped}
                  aria-expanded={cardFlipped}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
                {/* <ExpandCircleDownIcon sx={{mt: 1, color:"#ccc" }} /> */}
                
              </Box>
            </CardContent>
          </CardActionArea>
          <CardActions>
            { !disabled ?
              ( doing ?
                <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<DirectionsRunIcon />}
                    sx={{ mx: 0.5}}
                    size="large"
                    onClick={(event) => handleInteraction('didit')}
                    >
                    Yep
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<LocalHotelIcon />}
                    sx={{ mx: 0.5 }}
                    size="large"
                    onClick={(event) => handleInteraction('didntit')}
                    color="error">
                    Nope
                  </Button>
                </Box> :
                <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                  <Button
                    variant="contained"
                    startIcon={<DirectionsRunIcon />}
                    sx={{ mx: 0.5, width: '50%',}}
                    size="large"
                    onClick={(event) => handleInteraction('doit')}
                    >
                    Doit
                  </Button>
                  <LaterButton
                    mainOption='Later'
                    options={['Not Today', 'Not This Week']}
                    clickHandler={() => handleInteraction('later')}
                    subOptionClickHandler={(index) => {
                      if (index === 0)
                        optionClickCallback('snooze', 18)
                        setSnackbar({ open: true, message: "Snoozed till tomorrow", severity: "info" });
                      if (index === 1)
                        optionClickCallback('snooze', 24 * 7)
                        setSnackbar({ open: true, message: "Snoozed till next week", severity: "info" });
                    }} />
                </Box>
              ) :
              <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                <Button
                  variant="text"
                  fullWidth
                  color="inherit"
                  sx={{ mx: 0.5}}
                  disabled
                  size="large"
                  >
                    &nbsp;
                </Button>
              </Box>
            }
            
          </CardActions>
        </Card>
        }
    </Box>
  )
}

export default DoitSuggestion