import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LabelOutlinedIcon from '@mui/icons-material/Label';
import LaunchIcon from '@mui/icons-material/Launch';
import MergeIcon from '@mui/icons-material/Merge';
import AddIcon from '@mui/icons-material/Add';
import { Box, ButtonGroup, Divider, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDoits, updateDoit } from "../helpers/Database";
import { DoitLite } from './AppContainer';


const AppTags = () => {
  const [tags, setTags] = useState<string[]>(JSON.parse(localStorage.getItem("availableTags") || "[]"));
  const [tagDoitCount, setTagDoitCount] = useState<any>({});
  const [doits, setDoits] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    async function updateDoitCounts() {
      const doits = await getDoits();
      tags.forEach((tag) => {
        const count = doits.filter((doit) => doit.tags.includes(tag)).length;
        setTagDoitCount((prev: any) => {
          return {
            ...prev,
            [tag]: count,
          }
        });
      });
      setDoits(doits);
    }
    updateDoitCounts();
  }, [tags]);

  const renameTag = async (oldTag: string) => {
    let newTag = prompt("Rename tag:\nNote: Tag must be lowercase, numerical or _", oldTag);
    if (newTag) {
      newTag = newTag.toLowerCase();
      newTag = newTag.replace(/\s+/g, "_");
      newTag = newTag.replace(/[^a-z0-9_]/g, "");
      const newTags:string[] = tags.map((tag) => {
        if (tag === oldTag) {
          return newTag!;
        }
        return tag;
      });
      for (const doit of doits) {
        if (doit.tags.includes(oldTag)) {
          const doitLite:DoitLite = {
            name: doit.name,
            description: doit.description,
            type: doit.type,
            tags: doit.tags.map((tag: string) => {
              if (tag === oldTag) {
                return newTag!;
              }
              return tag;
            }),
          }
          await updateDoit(doit.localId, doitLite);
        }
      }
      // Update local storage
      localStorage.setItem("availableTags", JSON.stringify(newTags));
      setTags(newTags);
      setDoits(await getDoits());
    }
  }

  const deleteTag = async (tag: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(`Delete tag ${tag}?\nThis will remove the tag from all doits.`)) return;

    const newTags = tags.filter((t) => t !== tag);
    localStorage.setItem("availableTags", JSON.stringify(newTags));
    setTags(newTags);

    for (const doit of doits) {
      if (doit.tags.includes(tag)) {
        const doitLite:DoitLite = {
          name: doit.name,
          type: doit.type,
          description: doit.description,
          tags: doit.tags.filter((t: string) => t !== tag),
        }
        await updateDoit(doit.localId, doitLite);
      }
    }
    
    setDoits(await getDoits());
  }

  const mergeTag = async (tag: string) => {
    // eslint-disable-next-line no-restricted-globals
    let into = prompt(`Merge tag ${tag} into:\nNote: This will delete the tag ${tag} and merge it into the tag you specify.`);
    if (into) {
      into = into.toLowerCase();
      into = into.replace(/\s+/g, "_");
      into = into.replace(/[^a-z0-9_]/g, "");

      // Check if tag exists
      if (!tags.includes(into)) {
        // eslint-disable-next-line no-restricted-globals
        alert(`Tag ${into} does not exist.`);
        return;
      }

      // Check if tag is the same
      if (into === tag) {
        // eslint-disable-next-line no-restricted-globals
        alert(`Tag ${into} is the same as ${tag}.`);
        return;
      }

      // Update doits
      for (const doit of doits) {
        if (doit.tags.includes(tag)) {
          const doitLite:DoitLite = {
            name: doit.name,
            type: doit.type,
            description: doit.description,
            tags: doit.tags.map((t: string) => {
              if (t === tag) {
                return into;
              }
              if (t === into) {
                return null;
              }
              return t;
            }).filter((t: string | null) => t !== null),
          }
          await updateDoit(doit.localId, doitLite);
        }
      }

      // Update local storage
      const newTags = tags.filter((t) => t !== tag);
      localStorage.setItem("availableTags", JSON.stringify(newTags));
      setTags(newTags);
      setDoits(await getDoits());
    }
  }

  const addTag = async () => {
    let tag = prompt("Add tag:\nNote: Tag must be lowercase, numerical or _");
    if (!tag) return;
    tag = tag.toLowerCase();
    tag = tag.replace(/\s+/g, "_");
    tag = tag.replace(/[^a-z0-9_]/g, "");
    if (tags.includes(tag)) {
      // eslint-disable-next-line no-restricted-globals
      alert(`Tag ${tag} already exists.`);
      return;
    }
    const newTags = [...tags, tag];
    localStorage.setItem("availableTags", JSON.stringify(newTags));
    setTags(newTags);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
      <Box sx={{ width: '80%', maxWidth: '600px'}}>
        <Fab
          color="primary" aria-label="add"
          onClick={() => addTag()}
          sx={{ position: 'fixed', bottom: 80, right: 25 }}>
          <AddIcon />
        </Fab>
        <Typography sx={{mt: 2, userSelect: 'none'}} variant="h2" gutterBottom>
          Tags
        </Typography>
        <Divider sx={{ my: 2}} />
        <List dense={true}>
          { tags.map((tag) => (
            <ListItem
              secondaryAction={
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  <IconButton edge="end" aria-label="open" sx={{ml: 1}}
                    onClick={() => navigate(`/app/list?tags=${tag}`)}>
                    <LaunchIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="merge" sx={{ml: 1}}
                    onClick={() => mergeTag(tag)}>
                    <MergeIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="edit" sx={{ml: 1}}
                    onClick={() => renameTag(tag)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" color="error" aria-label="delete" sx={{ml: 1}}
                    onClick={() => deleteTag(tag)}>
                    <DeleteIcon />
                  </IconButton>
                </ButtonGroup>
              }>
              <ListItemAvatar>
              <LabelOutlinedIcon />
              </ListItemAvatar>
              <ListItemText
                primary={tag}
                secondary={`${tagDoitCount[tag]} doits`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
    
  );
};

export default AppTags;
