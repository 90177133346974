import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { dropWholeDatabase } from "../helpers/Database";
import PublicAppBar from "../views/PublicAppBar";

const OfflineOnboarding = () => {
  const navigate = useNavigate();
  const enableOffline = async () => {
    await localStorage.setItem("offlineMode", "true");
    await localStorage.setItem("authToken", "nah");
    navigate("/app");
  }
  const wipeData = async () => {
    if (window.confirm("Are you sure you want to wipe all your data?\nYou cannot get it back.\nI can't get it back")) {
      await dropWholeDatabase();
      window.location.reload();
    }
  }
  const hasData = localStorage.getItem("stats") !== null;
  return (
    <>
    <PublicAppBar showBanner={false} />
      {hasData ? (
        <Box sx={{my:3, p: 4}}>
          <Typography variant="h2" sx={{my:2}}>
            Welcome back to Doitlater Offline Mode!
          </Typography>
          <Typography variant="h4" sx={{my:2}}>
            You can use this app locally, no data will be stored or sent to the server.
          </Typography>
          <Typography variant="body1" sx={{my:2}}>
            Right now there is no way to sync the data to the server afterwards, but that feature is going to be added soon.
          </Typography>
          <Typography variant="body1" sx={{my:2}}>
            There is no way to export your data (yet).
          </Typography>
          <Typography variant="body1" sx={{my:2}}>
            If you clear your cache or browser data, all your data will be lost.
          </Typography>
          <Typography variant="body1" sx={{my:2}}>
            If you use a different computer or different browser, you will not have your data.
          </Typography>

          <Button
              color="secondary"
              variant="contained"
              size="large"
              sx={{ minWidth: 200, mx: 1, mb: { xs: 2, sm: 4 } }}
              onClick={enableOffline}
            >
            Let me back in!
          </Button>
          <Button
              color="error"
              sx={{ minWidth: 200, mx: 1, mb: { xs: 2, sm: 4 } }}
              onClick={() => wipeData()}
            >
            Delete all my data
          </Button>
        </Box>
      ) : (
        <Box sx={{my:3, p: 4}}>
          <Typography variant="h2" sx={{my:2}}>
            Welcome to Doitlater Offline Mode!
          </Typography>
          <Typography variant="h4" sx={{my:2}}>
            You can use this app locally, no data will be stored or sent to the server.
          </Typography>
          <Typography variant="body1" sx={{my:2}}>
            Right now there is no way to sync the data to the server afterwards, but that feature is going to be added soon.
          </Typography>
          <Typography variant="body1" sx={{my:2}}>
            There is no way to export your data (yet)
          </Typography>
          <Typography variant="body1" sx={{my:2}}>
            If you clear your cache or browser data, all your data will be lost.
          </Typography>
          <Typography variant="body1" sx={{my:2}}>
            If you use a different computer or different browser, you will not have your data
          </Typography>

          <Button
              color="secondary"
              variant="contained"
              size="large"
              sx={{ minWidth: 200, mb: { xs: 2, sm: 4 } }}
              onClick={enableOffline}
            >
            Let's go
          </Button>
        </Box>
      )}
    </>
    
  );
};

export default OfflineOnboarding;