import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Autocomplete, Chip, CircularProgress, Collapse, Divider, FormControlLabel, Paper, Switch, TextField } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from "@mui/system";
import { ChangeEvent, MouseEvent, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CurrentDoit from '../components/CurrentDoit';
import DoitList from "../components/DoitList";
import AppContext from "../helpers/AppContext";
import { getAvailableTags, getCurrentDoit, getDoits } from "../helpers/Database";
import { filterActiveDoits, filterCompleteDoits, filterDoitsByTags, filterUnsnoozedDoits } from "../helpers/Filters";
import { sortByLastInteraction } from "../helpers/Sorts";
import { Doit } from "./AppContainer";

type FilterType = "active" | "complete" | "all";
const AppPageList = () => {
  const { update, setUpdate, devStats, setDevStats } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [showSnoozed, setShowSnoozed] = useState(false);
  const [doits, setDoits] = useState<Doit[]>([]);
  const [viewMode, setViewMode] = useState<"cards"| "list">("cards");
  const [filter, setFilter] = useState<FilterType>("active");
  const [filterTags, setFilterTags] = useState<string[]>([]);

  const [currentDoit, setCurrentDoit] = useState<Doit|null>(null);
  let availableTags: string[] = getAvailableTags();

  const navigate = useNavigate();

  const updateDoits = useCallback(async () => {
    let dits = await getDoits();
    setDevStats({
      ...devStats,
      totalDoits: dits.length,
    });
    console.log("Updating doits", dits);

    if (filter === "active") {
      dits = filterActiveDoits(dits);
      if (!showSnoozed) {
        dits = filterUnsnoozedDoits(dits);
      }
    } else if (filter === "complete") {
      dits = filterCompleteDoits(dits);
    }

    if (filterTags.length > 0) {
      dits = filterDoitsByTags(dits, filterTags);
    }

    dits = sortByLastInteraction(dits);

    setCurrentDoit(await getCurrentDoit());
    setDoits(dits);
    setLoading(false);
  }, [filter, showSnoozed, filterTags, devStats, setDevStats]);

  const handleSnoozeChange = (event: ChangeEvent<HTMLElement>, newSnooze: boolean) => {
    setShowSnoozed(newSnooze);
    setUpdate((update) => !update);
  };
  
  const handleFilterChange = (event: MouseEvent<HTMLElement>, newFilter: FilterType) => {
    if (newFilter) {
      const currentURLParams = new URLSearchParams(window.location.search)
      currentURLParams.set("filter", newFilter);
      navigate({search: currentURLParams.toString()});
      setFilter(newFilter);
      setUpdate((update) => !update);
    }
  };

  const handleViewModeChange = (event: MouseEvent<HTMLElement>, newViewMode: "cards" | "list") => {
    if (newViewMode) {
      setViewMode(newViewMode);
    }
  };

  const handleTagChange = (event: ChangeEvent<{}>, value: string[]) => {
    const currentURLParams = new URLSearchParams(window.location.search)
    currentURLParams.set("tags", value.join(','));
    navigate({search: currentURLParams.toString()});
    setFilterTags(value);
    setUpdate((update) => !update);
  }

  const delayedUpdate = useCallback(() => {
    // TODO: Add a debounce here so it will only update after all the states are set
    // if (loading) return;
    setLoading(true);
    setTimeout(() => {
       // This is a hack because the database will return garbage if queried too quickly
      updateDoits();
    }, 50);
  }, [updateDoits, loading]);

  useEffect(() => {
    const currentURLParams = new URLSearchParams(window.location.search)
    const filterParam = currentURLParams.get("filter");
    if (filterParam) {
      setFilter(filterParam as FilterType);
    }
    const tagsParam = currentURLParams.get("tags");
    if (tagsParam) {
      setFilterTags(tagsParam.split(','));
    }
  }, [window.location.search]);

  useEffect(() => {
    delayedUpdate();
  }, [filter, filterTags, update]);

  return (
    <> 
      <Collapse in={currentDoit !== null}>
        <Box sx={{my:2, display:"flex", justifyContent: "center"} }>
          <CurrentDoit currentDoit={currentDoit} />
        </Box>
      </Collapse>
      <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', p: 1, m: 1, pb: 0, mb: 0, bgcolor: 'background.paper' }}>
        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
        <ToggleButtonGroup
            value={viewMode}
            exclusive
            disabled={loading}
            onChange={handleViewModeChange}
            aria-label="view"
            sx = {{ mx: 2, my: 1, height: {xs: '40px', sm: '47px', md: '55px'} }}
          >
            <ToggleButton value="cards" aria-label="cards">
              <ViewAgendaIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          
          <ToggleButtonGroup
            value={filter}
            exclusive
            disabled={loading}
            onChange={handleFilterChange}
            aria-label="filter doits"
            sx = {{ mx: 2, my: 1, height: {xs: '40px', sm: '47px', md: '55px'} }}
          >
            <ToggleButton value="active" aria-label="active">
              Doing
            </ToggleButton>
            <ToggleButton value="complete" aria-label="complete">
              Archived
            </ToggleButton>
          </ToggleButtonGroup>
          
          <Autocomplete
              multiple clearOnEscape openOnFocus autoComplete
              id="tags"
              value={filterTags}
              options={availableTags.map((option) => option)}
              limitTags={2}
              onChange={handleTagChange}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="tags"
                  name="tags"
                  placeholder="Start typing to add tags"
                  variant='outlined'
                />
              )}
              sx = {{ mx: 2, my: 1, width: 300 }}
            />
        </Box>
        
        <Collapse in={filter === "active"} timeout="auto" unmountOnExit>
          <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                color='info'
                checked={showSnoozed}
                onChange={handleSnoozeChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />}
            label="Show Snoozed" />
          </Box>
        </Collapse>
      </Paper>
      <Divider />
      { loading && <CircularProgress sx={{m:3}}/>}
      { !loading && <DoitList doits={doits} currentDoit={currentDoit} viewMode={viewMode} />}
    </>
  )
}

export default AppPageList;