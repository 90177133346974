import AddIcon from '@mui/icons-material/Add';
import { Divider } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from "react";
import AppContext from "../helpers/AppContext";
import { addInteraction, completeDoit, deleteDoit, setCurrentDoit, snoozeDoit, unsnoozeDoit } from "../helpers/Database";
import { Doit, InteractionType } from "../pages/AppContainer";
import { getAppSettings } from '../pages/AppSettings';
import DoitContextMenu, { DoitContextMenuOptions } from "./DoitContextMenu";
import DoitListCards from './DoitListCards';
import DoitListTable from './DoitListTable';

export interface DoitListProps {
  doits: Doit[];
  currentDoit: Doit | null;
  viewMode: 'list' | 'cards';
}

const DoitList = (props: DoitListProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedDoit, setSelectedDoit] = React.useState<Doit | null>(null);
  const { setUpdate, setEditDoit, setBackdateDoit, setSnackbar } = React.useContext(AppContext);
  const open = Boolean(anchorEl);
  const { doits, viewMode, currentDoit } = props;

  const appSettings = getAppSettings(); 


  const haveCurrentDoit = currentDoit !== null;
  const currentDoitId = haveCurrentDoit ? currentDoit!.localId : null;
  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>, doit: Doit) => {
    setAnchorEl(event.currentTarget);
    setSelectedDoit(doit);
  };
  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const optionClickCallback = async (type: DoitContextMenuOptions, hours?:number) => {
    switch (type) {
      case 'edit':
        if (selectedDoit) setEditDoit(selectedDoit.localId);
        break;
      case 'backdatedidit':
        if (selectedDoit) setBackdateDoit({ localId: selectedDoit?.localId, action: 'didit' });
        break;
      case 'backdatelater':
        if (selectedDoit) setBackdateDoit({ localId: selectedDoit?.localId, action: 'later' });
        break;
      case 'didit':
        await addInteraction(selectedDoit!, 'didit');
        await snoozeDoit(selectedDoit!, appSettings.doitCompletionSnooze);
        if (selectedDoit?.type === 'once') {
          await completeDoit(selectedDoit!, true);
          setSnackbar({ open: true, message: `Doit completed and archived`, severity: "info" });
        }
        setUpdate((update) => !update);
        break;
      case 'complete':
        await completeDoit(selectedDoit!);
        setUpdate((update) => !update);
        break;
      case 'snooze':
        await snoozeDoit(selectedDoit!, hours!);
        await addInteraction(selectedDoit!, 'snooze');
        setUpdate((update) => !update);
        break;
      case 'delete':
        await deleteDoit(selectedDoit!);
        setUpdate((update) => !update);
        break;
    }
  }

  const handleInteraction = async (doit: Doit, interactionType: InteractionType) => {
    console.log("Interaction Type: ", interactionType);
    // Create Interaction
    await addInteraction(doit!, interactionType);
    switch (interactionType) {
      case 'doit':
        setCurrentDoit(doit);
        setUpdate((update) => !update);
        break;
      case 'unsnooze':
        await unsnoozeDoit(doit!);
        setUpdate((update) => !update);
        break;
    }
  }
  
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', p: 1, m: 1, pt: 0, mt: 0,  bgcolor: 'background.paper' }}>
      <DoitContextMenu anchorEl={anchorEl} open={open} handleClose={handleSettingsClose} optionClickCallback={optionClickCallback} doing={selectedDoit?.localId === currentDoitId}/>
      { (!doits || doits.length === 0) && (
        <Box sx={{my:3}}>
          <Typography variant="h4" gutterBottom>
            There's nothing in my list!
          </Typography>
          <Typography variant="body1" gutterBottom>
            (Really though?)
          </Typography>
          <Divider sx={{ my: 3 }}/>
          <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
            Add a doit!
          </Typography>
          <Typography variant="body2">
            Click on the <AddIcon fontSize="small"/> botton in the bottom right corner.
          </Typography>

          <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
            Mess with the filters above
          </Typography>
          <Typography variant="body2">
            Maybe I'm not looking hard enough??
          </Typography>
        </Box>
        
      )}
      { doits.length > 0 &&
        <Box sx={{my:1}}>
          <Typography variant="body2" color="text.secondary">
            {doits.length} doits
          </Typography>
        </Box>
      }
      { doits.length > 0 && 
        (viewMode === 'cards' ?
          <DoitListCards
            doits={doits}
            currentDoitId={currentDoitId}
            handleInteraction={handleInteraction}
            handleSettingsClick={handleSettingsClick}
          /> :
          <DoitListTable
            doits={doits}
            currentDoitId={currentDoitId}
            handleInteraction={handleInteraction}
            handleSettingsClick={handleSettingsClick}
          />
          )
      }
    </Box>
  )
}
export default DoitList;