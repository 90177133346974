import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../helpers/AppContext";

const SignOut = () => {
  const navigate = useNavigate();
  const { setLoggedIn } = React.useContext(AppContext);

  useEffect(() => {
    setLoggedIn(false);
    localStorage.removeItem("authToken");
    localStorage.removeItem("offlineMode");
    navigate("/");
  }, [navigate, setLoggedIn]);
  
  return <h1>Signing Out</h1>;
};

export default SignOut;