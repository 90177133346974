import { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Badge, Box, CardActionArea, Chip, Divider } from '@mui/material';
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { format, formatISO9075 } from 'date-fns';

import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SnoozeIcon from '@mui/icons-material/Snooze';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { InteractionDayMetadata, InteractionFlags } from '../components/InteractionDayList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMore from '../components/ExpandMore';

export interface InteractionDayProps {
  interactionDayMetadata: InteractionDayMetadata;
}

const InteractionDay = (props: InteractionDayProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { interactionDayMetadata } = props;
  const { date: propDate, didit, latered, snoozed, interactions } = interactionDayMetadata;
  const interactionValues = Object.values(interactions);
  const date = parseISO(propDate)
  const dayOfWeek = format(date, 'EEE')
  const daysAgo = formatISO9075(new Date(), {representation: 'date'}) === propDate ? 'Today' : formatDistanceToNow(date) + ' ago';

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', bgcolor: 'background.paper' }}>
      <Card sx={{ mx: 2, my: 1, width: '90%', maxWidth: 500, textAlign: "left" }}>
        <CardActionArea onClick={handleExpandClick}>
          <CardHeader
            sx={{ mb: 1, pb: 0 }}
            title={daysAgo + ' (' + dayOfWeek + ')'}
            subheader={
              <Box sx={{verticalAlign: 'middle'}}>
                <CalendarMonthIcon sx={{ fontSize: '1em', mr: 0.5, mt:0.2 }} />
                {propDate}
              </Box>
            }
            action={
              <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
          />
          <CardActions>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Badge badgeContent={didit} color="secondary" sx={{ mr: 1.5 }}>
                  <Chip size="small" label="Didit" icon={<DirectionsRunIcon />} color={didit>0?'primary':undefined} />
                </Badge>
                <Badge badgeContent={latered} color="secondary" sx={{ mr: 1.5 }}>
                  <Chip size="small" label="Latered" icon={<LocalHotelIcon />} color={latered>0?'warning':undefined} />
                </Badge>
                <Badge badgeContent={snoozed} color="secondary" sx={{ mr: 1.5 }}>
                  <Chip size="small" label="Snoozed" icon={<SnoozeIcon />} color={snoozed>0?'info':undefined} />
                </Badge>
              </Box>
              
            </Box>
          </CardActions>
        </CardActionArea>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ pt: 0, pb: 1 }}>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <List sx={{ width: '100%' }}>
              {interactionValues.map((value: InteractionFlags) => (
                <Box key={value.name}>
                 <Divider />
                 <ListItem disableGutters >
                    <Typography variant="body1" color="text.primary" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', flexGrow: 1}}>
                      {value.name}
                    </Typography>
                    {value.didit && <DirectionsRunIcon color="primary" sx={{ ml: 0.5 }} />}
                    {value.latered && <LocalHotelIcon color="warning" sx={{ ml: 0.5 }} />}
                    {value.snoozed && <SnoozeIcon color="info" sx={{ ml: 0.5 }} />}
                  </ListItem>
                </Box>
              ))}
              <Divider />
            </List>
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center'}}>
              {didit + latered + snoozed} unique actions today
            </Typography>
          </Box>
          </CardContent>
        </Collapse>
      </Card>
    </Box>

  )

}

export default InteractionDay;