import { Outlet, Link } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import AppAppBar from "../views/AppAppBar";
import AppFooter from "../views/AppFooter";
import { Box } from "@mui/material";

const Layout = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Outlet />
        <AppFooter />
      </Box>
    </ThemeProvider>
  )
};

export default Layout;