import { Box, Divider, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { getStats, UserStats } from "../helpers/Stats";

const StatsStats = () => {
  const stats:UserStats = getStats();
  const accountCreation = parseISO(stats.accountCreatedDate);
  const distance = formatDistanceToNowStrict(accountCreation);
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        App used since: {distance} ago
      </Typography>
      <Divider sx={{ my: 3 }}/>
      <Typography variant="h3" gutterBottom>
        Current Streaks
      </Typography>
      <Box sx={{ display: 'flex', justifyContent:"center"}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Didit
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.streaks.didit} days
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Latered
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.streaks.later} days
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Login
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.streaks.login} days
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 3 }}/>
      <Typography variant="h3" gutterBottom>
        Highest Streaks
      </Typography>
      <Box sx={{ display: 'flex', justifyContent:"center"}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Didit
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.highestStreak.didit} days
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Latered
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.highestStreak.later} days
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Login
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.highestStreak.login} days
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 3 }}/>
      <Typography variant="h3" gutterBottom>
        Totals
      </Typography>
      <Box sx={{ display: 'flex', justifyContent:"center"}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Created<br/>Doits
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.totals.created}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Completed<br/>Doits
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.totals.completed}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Doits<br/>Done
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.totals.didit}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            Doits<br/>Latered
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.totals.later}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1 }}>
          <Typography variant="h5" gutterBottom>
            App<br/>Opened
          </Typography>
          <Typography variant="body2" gutterBottom>
            {stats.totals.login}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 3 }}/>
      <Typography variant="h3" gutterBottom>
        Most Done Doit
      </Typography>
      <Typography variant="h5" gutterBottom>
        Coming Soon
      </Typography>
      <Divider sx={{ my: 3 }}/>
      <Typography variant="h3" gutterBottom>
        Most Procrastinated Doit
      </Typography>
      <Typography variant="h5" gutterBottom>
        Coming Soon
      </Typography>
    </Box>
  );
};


export default StatsStats;