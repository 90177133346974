import { Doit } from '../pages/AppContainer';


export const filterCompleteDoits = (doits: Doit[]): Doit[] => {
  return doits.filter((doit) => {
    if (doit.status === "completed") {
      return true;
    }
    return false;
  });
}

export const filterActiveDoits = (doits: Doit[]): Doit[] => {
  return doits.filter((doit) => {
    if (doit.status !== "active") {
      return false;
    }
    return true;
  });
}

// Shows the unsnoozed doits
export const filterUnsnoozedDoits = (doits: Doit[]): Doit[] => {
  const currentTimestamp = Date.now();
  return doits.filter((doit) => {
    if (!doit.snoozeTill) {
      return true;
    }
    return doit.snoozeTill < currentTimestamp;
  });
}

// Shows only the snoozed doits
export const filterSnoozedDoits = (doits: Doit[]): Doit[] => {
  const currentTimestamp = Date.now();
  return doits.filter((doit) => {
    if (doit.snoozeTill) {
      return doit.snoozeTill >= currentTimestamp;
    }
    return true;
  });
}

export const filterDoitsByTags = (doits: Doit[], tags: string[]): Doit[] => {
  return doits.filter((doit) => {
    if (doit.tags) {
      for (let i = 0; i < doit.tags.length; i++) {
        if (tags.includes(doit.tags[i])) {
          return true;
        }
      }
    }
    return false;
  });
}
