import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Badge, Box, CardActionArea, Chip, Divider, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { format, formatDistanceToNowStrict, formatISO9075 } from 'date-fns';
import { useState } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import SnoozeIcon from '@mui/icons-material/Snooze';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { addDays } from 'date-fns/esm';
import ExpandMore from '../components/ExpandMore';
import { InteractionDoitCondensed } from '../components/InteractionDoitList';


export interface InteractionDoitProps {
  interactionDoit: InteractionDoitCondensed,
  handleFavouriteClick: (e:any, doitId: string) => void,
  favourite: boolean,
  timespan: string,
}

const InteractionDoit = (props: InteractionDoitProps) => {
  const { favourite, interactionDoit, timespan, handleFavouriteClick } = props;
  const { doitId, title, lastDone, lastSkipped, interactions } = interactionDoit;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // const { interactionDoitMetadata } = props;

  // Count number of didits
  let diditCount = 0;
  let lateredCount = 0;
  let snoozedCount = 0;

  const dateHash:any = {};
  const dateArray:any = [];

  // Create the date hash and array
  let date = new Date();
  let daysToGenerate = 7;
  if (timespan === 'd14') daysToGenerate = 14;
  if (timespan === 'd28') daysToGenerate = 28;

  for (let i = 0; i < daysToGenerate; i++) {
    const d = addDays(date, -i);
    const dateKey = formatISO9075(d, { representation: 'date' });
    dateHash[dateKey] = {
      didit: 0, later: 0, snoozed: 0
    };
    let dateTitle = 'Today';
    let ago = '';
    if (i > 0) {
      dateTitle = format(d, 'EEE, d MMM')
      ago = formatDistanceToNowStrict(d, { addSuffix: true });
    }
    dateArray.push(
      {
        date: dateTitle,
        ago,
        dateCounts: dateHash[dateKey]
      }
    )
  }

  for (const interaction of interactions) {
    if (interaction.date in dateHash) {
      dateHash[interaction.date][interaction.type] += interaction.count;
      if (interaction.type === 'didit') {
        diditCount++;
      } else if (interaction.type === 'later') {
        lateredCount++;
      } else if (interaction.type === 'snooze') {
        snoozedCount++;
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', bgcolor: 'background.paper' }}>
      <Card sx={{ mx: 2, my: 1, width: '90%', maxWidth: 500, textAlign: "left" }}>
        <CardActionArea onClick={handleExpandClick}>
          <CardHeader
            sx={{ mb: 1, pb: 0 }}
            title={
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center', mb: 1 }}>
                <ListAltIcon sx={{ mr: 1, mt: -0.2, fontSize: '1.2em'}} />
                <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                  {title}
                </Typography>
              </Box>
            }
            action={
              <Box sx={{ display: 'flex', mt:-1}}>
                <Box sx={{ pt: 1, mr: 1 }}>
                  <ExpandMore
                    expand={expanded}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Box>
                
                <IconButton
                  aria-label="favourite"
                  onClick={(e) => handleFavouriteClick(e, doitId)}
                >
                  { favourite ? <FavoriteIcon color="primary"/> : <FavoriteBorderIcon /> }
                </IconButton>
              </Box>
              
            }
          />
          <CardActions>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Badge badgeContent={diditCount > 0 ? diditCount + "d" : 0} color="secondary" sx={{ mr: 2 }}>
                  <Chip size="small" label="Didit" color={diditCount > 0 ? 'primary' : undefined} icon={<DirectionsRunIcon />} />
                </Badge>
                <Badge badgeContent={lateredCount > 0 ? lateredCount + "d" : 0} color="secondary" sx={{ mr: 2 }}>
                  <Chip size="small" label="Latered" color={lateredCount > 0 ? 'warning' : undefined} icon={<LocalHotelIcon />}/>
                </Badge>
                <Badge badgeContent={snoozedCount > 0 ? snoozedCount + "d" : 0} color="secondary" sx={{ mr: 2 }}>
                  <Chip size="small" label="Snoozed" color={snoozedCount > 0 ? 'info' : undefined} icon={<SnoozeIcon />}/>
                </Badge>
                <Typography variant="caption" color="text.secondary" sx={{mt:0.2}}>
                  within the last {daysToGenerate} days
                </Typography>
              </Box>
              
            </Box>
          </CardActions>
        </CardActionArea>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ pt: 0, pb: 1 }}>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <List sx={{ width: '100%' }}>
              { dateArray.map((date: any) => (
                <Box key={date.date}>
                  <Divider />
                  <ListItem disableGutters >
                    <Typography variant="body1" color="text.primary" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', minWidth:110}}>
                      {date.date}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', flexGrow: 1}}>
                      {date.ago}
                    </Typography>
                    <Chip size="small" sx={{ mr: { xs: 0.5, sm: 1 }, px: { xs: 0, sm: 0.5, md: 1 }, py: 1.5 }} color={date.dateCounts.didit > 0 ? 'primary' : undefined} icon={<DirectionsRunIcon />} label={date.dateCounts.didit} />
                    <Chip size="small" sx={{ mr: { xs: 0.5, sm: 1 }, px: { xs: 0, sm: 0.5, md: 1 }, py: 1.5 }} color={date.dateCounts.later > 0 ? 'warning' : undefined} icon={<LocalHotelIcon />} label={date.dateCounts.later} />
                    <Chip size="small" sx={{ mr: { xs: 0.5, sm: 1 }, px: { xs: 0, sm: 0.5, md: 1 }, py: 1.5 }} color={date.dateCounts.snoozed > 0 ? 'info' : undefined} icon={<SnoozeIcon />} label={date.dateCounts.snoozed} />
                  </ListItem>
                </Box>
              ))}
              <Divider />
            </List>
          </Box>
          </CardContent>
        </Collapse>
      </Card>
    </Box>

  )

}

export default InteractionDoit;