import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import SnoozeIcon from '@mui/icons-material/Snooze';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ArchiveIcon from '@mui/icons-material/Archive';
import HotelIcon from '@mui/icons-material/Hotel';
import { Collapse, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from 'react';

export type DoitContextMenuOptions = "edit" | "delete" | "snooze" | "didit" | "complete" | "backdatedidit" | "backdatelater";
export interface DoitContextMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  optionClickCallback: (type: DoitContextMenuOptions, hours?: number) => void;
  handleClose: () => void;
  doing: boolean;
}

const DoitContextMenu = (props: any) => {
  const ITEM_HEIGHT = 48;

  const { anchorEl, open, optionClickCallback, handleClose, doing } = props;
  const onClickHandler = (type: DoitContextMenuOptions, hours?: number) => {
    handleClose();
    optionClickCallback(type, hours);
  };

  const [snoozeOpen, setSnoozeOpen] = useState(false);
  const [backdateOpen, setBackdateOpen] = useState(false);

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 6.5,
          width: '20ch',
        },
      }}
    > 
      <Collapse in={!snoozeOpen && !backdateOpen}>
        <MenuItem onClick={() => onClickHandler('edit', 0)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        { !doing && <>
          <MenuItem onClick={() => setSnoozeOpen(true)}>
            <ListItemIcon>
              <SnoozeIcon sx={{color: '#0288d1'}} fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{color: '#0288d1'}}>Snooze </ListItemText>
            <ArrowForwardIcon sx={{color: '#0288d1'}} fontSize="small" />
          </MenuItem>
          <MenuItem onClick={() => setBackdateOpen(true)}>
            <ListItemIcon>
              <SnoozeIcon sx={{color: 'primary'}} fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{color: 'primary'}}>Backdate</ListItemText>
            <ArrowForwardIcon sx={{color: 'primary'}} fontSize="small" />
          </MenuItem>
          <MenuItem onClick={() => onClickHandler('didit', 0)}>
            <ListItemIcon>
              <DirectionsRunIcon sx={{color: 'primary'}} fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{color: 'primary'}}>Record Doit</ListItemText>
          </MenuItem>

          <MenuItem onClick={() => onClickHandler('complete', 0)}>
            <ListItemIcon>
              <ArchiveIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Archive</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => onClickHandler('delete', 0)}>
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" sx={{color: 'red'}} />
            </ListItemIcon>
            <ListItemText sx={{color: 'red'}}>Delete</ListItemText>
          </MenuItem>
        </> }
        
      </Collapse>
      <Collapse in={snoozeOpen}>
        <MenuItem onClick={() => {setSnoozeOpen(false)}}>
          <ListItemIcon>
            <ArrowBackIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Snooze for...</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => onClickHandler('snooze', 1)}>
          <ListItemIcon>
            <SnoozeIcon sx={{color: '#0288d1'}} fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{color: '#0288d1'}}>+ 1 hour</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onClickHandler('snooze', 12)}>
          <ListItemIcon>
            <SnoozeIcon sx={{color: '#0288d1'}} fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{color: '#0288d1'}}>+ 12 hours</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onClickHandler('snooze', 24)}>
          <ListItemIcon>
            <SnoozeIcon sx={{color: '#0288d1'}} fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{color: '#0288d1'}}>+ 1 day</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onClickHandler('snooze', 24 * 3)}>
          <ListItemIcon>
            <SnoozeIcon sx={{color: '#0288d1'}} fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{color: '#0288d1'}}>+ 3 days</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onClickHandler('snooze', 24 * 7)}>
          <ListItemIcon>
            <SnoozeIcon sx={{color: '#0288d1'}} fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{color: '#0288d1'}}>+ 1 week</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onClickHandler('snooze', 24 * 30)}>
          <ListItemIcon>
            <SnoozeIcon sx={{color: '#0288d1'}} fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{color: '#0288d1'}}>+ 1 month</ListItemText>
        </MenuItem>
      </Collapse>
      <Collapse in={backdateOpen}>
        <MenuItem onClick={() => {setBackdateOpen(false)}}>
          <ListItemIcon>
            <ArrowBackIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Backdate...</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => onClickHandler('backdatedidit')}>
          <ListItemIcon>
            <DirectionsRunIcon sx={{color: 'primary'}} fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{color: 'primary'}}>Doit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onClickHandler('backdatelater')}>
          <ListItemIcon>
            <HotelIcon sx={{color: 'warning'}} fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{color: 'warning'}}>Later</ListItemText>
        </MenuItem>
      </Collapse>
    </Menu>
  );
};

export default DoitContextMenu;