import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Avatar } from '@mui/material';
import { useContext } from 'react';
import AppContext from '../helpers/AppContext';

function ProductFAQ() {
  const { procrastinateMode, getAppText } = useContext(AppContext);
  const faq = getAppText('welcome_faqs', procrastinateMode);
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
      <Button
        sx={{
          border: '4px solid black',
          borderRadius: 0,
          height: 'auto',
          py: 2,
          px: 5,
        }}
        onClick={() => {
          alert(getAppText('welcome_faq_alert', procrastinateMode));
        }}
      >
        <Typography variant="h4" component="span">
          { getAppText('welcome_faq_title', procrastinateMode) }
        </Typography>
      </Button>
      <Typography variant="subtitle1" sx={{ my: 3 }}>
        <div dangerouslySetInnerHTML={{__html: getAppText('welcome_faq_response', procrastinateMode).replaceAll("\n", "<br/>")}} />
      </Typography>
      <Avatar sx={{ m: 1, color: 'primary.main', bgcolor: 'transparent', width: '70px', height: '70px'}}>
        <LiveHelpIcon fontSize="large"/>
      </Avatar>
      {
        faq.map((item: any, index: any) => {
          return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {item.q}
              </Typography>
              <Typography variant="body1" sx={{ my: 1 }}>
                <span dangerouslySetInnerHTML={{__html: item.a.replaceAll("\n", "<br/>")}} />
              </Typography>
            </Box>
          )
        })
      }
    </Container>
  );
}

export default ProductFAQ;
