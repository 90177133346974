import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import InteractionDayList from "../components/InteractionDayList";
import InteractionDoitList from "../components/InteractionDoitList";
import StatsStats from "../views/StatsStats";
import AppContext from "../helpers/AppContext";
import { getRecentInteractions } from "../helpers/Database";
import { Interaction } from "./AppContainer";
import InteractionTagsList from "../components/InteractionTagsList";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 2, py: 1, m: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const AppPageInteractions = () => {
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [currentTab, setCurrentTab] = useState(0);
  const { devStats, setDevStats } = useContext(AppContext);

  const updateInteractions = useCallback(async () => {
    console.log("Updating interactions");
    const ints = await getRecentInteractions();
    setDevStats({
      ...devStats,
      totalInteractions: ints.length,
    });
    setInteractions(ints);
  }, [devStats, setDevStats]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    sessionStorage.setItem('interactionsTab', newValue.toString());
  };

  useEffect(() => {
    const tab = sessionStorage.getItem('interactionsTab');
    if (tab) {
      setCurrentTab(parseInt(tab));
    }
    updateInteractions();
  }, []);
  
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="interaction tabs" centered>
          <Tab label="By Days" />
          <Tab label="By Doits" />
          <Tab label="By Tags" />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <InteractionDayList interactions={interactions} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <InteractionDoitList interactions={interactions} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <InteractionTagsList interactions={interactions} />
      </TabPanel>
    </>
  )
}

export default AppPageInteractions;