import { useNavigate } from "react-router-dom";

import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "@mui/material";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelOutlinedIcon from '@mui/icons-material/Label';
import { useContext } from "react";
import SyncStatus from "../components/SyncStatus";
import Typography from "../components/Typography";
import AppContext from "../helpers/AppContext";
import { getAppSettings } from "../pages/AppSettings";
import { appVersion } from "../components/ChangelogModal";

const container = window.document.body;

interface AppDrawerProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  drawerWidth: number;
}

const AppDrawer = (props: AppDrawerProps) => {
  const navigate = useNavigate();

  const clickNavigate = (path: string) => {
    navigate(path);
    props.handleDrawerToggle();
  }
  
  const { devStats } = useContext(AppContext);
  const appSettings = getAppSettings();

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', zIndex: 9999}}>
      <SyncStatus sx={{ p: 2, justifyContent: 'left', width: '100%', backgroundColor: 'secondary.dark' }} />
      <Divider />
      <List>
        <ListItem disablePadding
          onClick={() => clickNavigate('/app/settings')}>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={"Settings"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding
          onClick={() => clickNavigate('/app/tags')}>
          <ListItemButton>
            <ListItemIcon>
              <LabelOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Edit Tags"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding
          onClick={() => {
            localStorage.removeItem('firstTimeDone')
            window.location.reload();
          }
          }>
          <ListItemButton>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={"Quickstart Guide"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding
          onClick={() => clickNavigate('/signout')}>
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Sign Out"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <Box sx={{ p: 2, mt: 1, position: 'absolute', bottom: '0', textAlign: 'center' }}>
        { !!appSettings.developerMode && <>
          <Typography sx={{my: 0.5}} variant="body1" color="text.secondary">
            Developer Mode
          </Typography>
          <Typography sx={{my: 0.5}} variant="caption" color="text.secondary">
            These only update when you visit a page that loads them
          </Typography>
          <Typography sx={{my: 0.5}} variant="body2" color="text.secondary">
            <strong>{devStats.totalDoits}</strong> doits
          </Typography>
          <Typography sx={{my: 0.5}} variant="body2" color="text.secondary">
            <strong>{devStats.totalInteractions}</strong> interactions
          </Typography>

          <Divider sx={{ my: 2 }}/>
        </>}
        <Typography sx={{my: 0.5}} variant="body2" color="text.secondary">
          App version v{appVersion}
        </Typography>
        <Typography sx={{my: 0.5}} variant="body2" color="text.secondary">
          {'©'}
          <Link color="inherit" href="https://doitlater.app/">
            Doitlater
          </Link>
          {' '} {new Date().getFullYear()}
        </Typography>
        <Typography sx={{my: 0.5}} variant="body2" color="text.secondary">
          {' from '}
          <Link color="inherit" href="https://github.com/cjx3711">
            @cjx3711
          </Link>
        </Typography>
        <Box sx={{ height: '10px' }} />
        <Link href="https://ko-fi.com/cjx3711" target="_blank" rel="noopener">
          <img src="/kofi_button_stroke.png" alt="support" width="100%" />
        </Link>
        <Box sx={{ height: {xs: '70px', md: 0} }} />
      </Box>
    </Box>
  );
  return (
    <Box
      component="nav"
      sx={{ width: { md: props.drawerWidth }, flexShrink: { md: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default AppDrawer;
