import { Alert, AlertTitle, Autocomplete, Box, Button, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { sampleSize, shuffle } from "lodash";
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAvailableTags, getDoit } from "../helpers/Database";
import { DoitLite } from "../pages/AppContainer";
import Typography from "./Typography";
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';

export interface AddDoitModalProps {
  open: boolean;
  editDoit?: string;
  setOpen: (open: boolean) => void;
  setEditDoit: (doit: string) => void;
  addDoitCallback: (formData: DoitLite) => void;
  editDoitCallback: (doitLocalId:string, formData: DoitLite) => void;
}

const AddDoitModal = (props: AddDoitModalProps) => {
  let availableTags: string[] = getAvailableTags();

  const navigate = useNavigate();

  const [doitString, setDoitString] = useState("");
  const exampleDoits = [
    "Practice guitar",
    "Practice piano",
    "Learn Python",
    "Clean the house",
    "Clean the car",
    "Clean the garage",
    "Go for a walk",
    "Go for a run",
    "Do some yoga",
    "Do some meditation",
    "Do some stretching",
    "Do some pushups",
    "Draw a picture",
    "Practice drawing",
    "Practice painting",
    "Pack for a trip",
    "Tidy up the room",
    "Tidy up the office",
    "Call mum",
    "Call dad",
    "Call grandma",
    "Call grandpa",
    "Hang out with friends",
  ];

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [changesMade, setChangesMade] = useState(false);
  const [showHelp, setShowHelp] = useState(true);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const { editDoitCallback, addDoitCallback, open, setOpen, editDoit, setEditDoit } = props;

  const [ doitType, setDoitType ] = useState<'once'|'recurring'>('recurring');
  const handleDoitTypeChange = (event: React.MouseEvent<HTMLElement>, newDoitType: 'once'|'recurring') => {
    setDoitType(newDoitType);
  };
  
  const getDoits = useCallback((count: number) => {
    return sampleSize(shuffle(exampleDoits), count).join(", ") + "...";; 
  }, [exampleDoits]);

  const randomiseDoits = useCallback(() => {
    setDoitString(getDoits(2));
  }, [setDoitString, getDoits]);

  useEffect(() => {
    if (editDoit) {
      console.log("Edit doit: " + editDoit);
      getDoit(editDoit).then((doit) => {
        setName(() => doit.name);
        setDescription(() => doit.description);
        setTags(() => doit.tags);
        setChangesMade(false);
      });
    } else {
      setName('');
      setDescription('');
      setTags([]);
      setChangesMade(false);
    }
  }, [editDoit]);

  useEffect(() => {
    // Get available tags from localStorage
    randomiseDoits();
    if (editDoit) {
      console.log("Edit doit: " + editDoit);
      getDoit(editDoit).then((doit) => {
        setName(() => doit.name);
        setDescription(() => doit.description);
        setTags(() => doit.tags);
        console.log("Tags" , doit.tags)
      });
    } else {
      setName('');
      setDescription('');
      setTags([]);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setEditDoit("");
  };

  const handleAddDoit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData: DoitLite = {
      name: name,
      type: doitType,
      description: description,
      tags: tags,
    };
    if (name === '') {
      alert("Title cannot be blank");
      return;
    }
    if (changesMade) {
      if (editDoit) {
        console.log("Edit doit: " + editDoit);
        editDoitCallback(editDoit, formData);
      } else {
        addDoitCallback(formData);
      }
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleAddDoit}>
        <DialogTitle>{editDoit ? "Edit Doit" : "Add Doit"}</DialogTitle>
        
        <DialogContent>
          <DialogContentText>
            <Alert severity="info" sx={{ mb: 2 }} 
              action={
                <Button color="inherit" size="small" onClick={() => setShowHelp(!showHelp)}>
                  {showHelp ? "Hide" : "Show"}
                </Button>
              }>
              <AlertTitle>What even is a <em>Doit</em>?</AlertTitle>
              <Collapse in={showHelp}>
                <Box component="ul" sx={{m: 0, paddingInlineStart: '1.2em'}}>
                  <li>Something you should do semi-regularly but don't</li>
                  <li>Something you intend on doing someday, far away</li>
                </Box>
              </Collapse>
            </Alert>
          </DialogContentText>
          
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Whatcha (supposed to be) doin?"
            value={name}
            autoComplete="off"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
              setChangesMade(true);
            }}
            placeholder={doitString}
            fullWidth
            onClick={() => { randomiseDoits(); }}
          />
          <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'center'}}>
            <ToggleButtonGroup
              value={doitType}
              exclusive
              disabled={!!editDoit}
              size="small"
              onChange={handleDoitTypeChange}
              aria-label="Change doit type"
              sx = {{ mr: 2, my: 1 }}
            >
              <ToggleButton value="recurring" aria-label="Recurring"> Recurring </ToggleButton>
              <ToggleButton value="once" aria-label="One Time"> One Time <LooksOneOutlinedIcon sx={{ml:1}}/></ToggleButton>
            </ToggleButtonGroup>
            <Collapse in={doitType === 'once'}>
              <Box sx={{display: 'flex', flexDirection: 'column', textAlign: {xs: 'center', sm: 'left'}}}>
                <Typography variant="body1" component="div" sx={{fontSize: '1em'}}>
                  Auto-archived once done
                </Typography>
                <Typography variant="caption" component="div" color={'#999'}>
                  { editDoit ? 'Cannot change' : 'Cannot be changed once created' }
                </Typography>
              </Box>
            </Collapse>            
          </Box>
          <Collapse in={showAdditionalFields}>
            <TextField
              margin="dense"
              id="description"
              name="description"
              label="Describe it if you want (optional)" 
              autoComplete="off"
              value={description}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDescription(event.target.value);
                setChangesMade(true);
              }}
              multiline
              fullWidth
            />
            <Autocomplete
              multiple clearOnEscape openOnFocus autoComplete
              id="tags"
              value={tags}
              options={availableTags.map((option) => option)}
              onChange={(_, value) => {
                setTags(value);
                setChangesMade(true);
              }}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="tags (optional)"
                  name="tags"
                  placeholder="Start typing to add tags"
                />
              )}
              sx = {{ mt: 1 }}
            />
            <Button size="small" color="info" sx={{ fontSize: '0.7em', mt: 1, display: 'block' }} onClick={() => {
              navigate('/app/tags')
              setOpen(false);
            }}>
              Click here to manage tags
            </Button>
          </Collapse>
          <Button size="small" color="secondary" onClick={() => { setShowAdditionalFields(!showAdditionalFields); }} fullWidth>
            {showAdditionalFields ? "Less" : "More"} fields
          </Button>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button type="submit" color="primary">{ editDoit ? 'Editit': 'Addit' }</Button>
        </DialogActions>
      </Box>
      
    </Dialog>
  )
}

export default AddDoitModal;