import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { dropWholeDatabase, getDoits, getInteractions, setDoits, setInteractions } from "../helpers/Database";


export const getAppSettings = () => {
  let appSettings = {
    doitCompletionSnooze: 12,
    developerMode: 0
  };

  if (localStorage.getItem('appSettings')) {
    appSettings = JSON.parse(localStorage.getItem('appSettings') as string);
  } else {
    localStorage.setItem('appSettings', JSON.stringify(appSettings));
  }
  return appSettings;
}

const saveAppSettings = (appSettings: any) => {
  localStorage.setItem('appSettings', JSON.stringify(appSettings));
}

const AppSettings = () => {
  const [appSettings, setAppSettings] = useState(getAppSettings());
  const [showDeveloperMode, setShowDeveloperMode] = useState(1);

  const [displayExport, setDisplayExport] = useState('');

  const [importedData, setImportedData] = useState('');


  const exportData = async () => {
    const localStorageString = JSON.stringify(localStorage);
    const doits = await getDoits()
    const doitString = JSON.stringify(doits);
    const interactions = await getInteractions()
    const interactString = JSON.stringify(interactions);

    setDisplayExport(localStorageString + "\n<|delimiter|>\n" + doitString + "\n<|delimiter|>\n" + interactString);
  }

  const importData = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to import data? This will overwrite your current data.") === false) {
      return;
    }

    try {
      const [importLocalhost, importDoits, importInteractions] = importedData.split("<|delimiter|>").map((item: string) => JSON.parse(item.trim()));
      if (importLocalhost === undefined || importDoits === undefined || importInteractions === undefined) {
        alert("Invalid data");
        return;
      }

      await dropWholeDatabase();
      localStorage.clear();

      // Need to wait a bit before writing to the database if not it'll lose data
      setTimeout(async () => {
        const appSettings = importLocalhost;
        const whitelistedKeys = ['appSettings', 'lastLogin', 'authToken', 'statsDirty', 'databaseVersion', 'availableTags', 'offlineMode', 'stats', 'statsLoginDaysDirty', 'firstTimeDone', 'statLoginDays', 'currentAppVersion', 'doitFaves', 'tagFaves' ];
        for (const key in appSettings) {
          if (whitelistedKeys.includes(key)) {
            localStorage.setItem(key, appSettings[key]);
            console.log("Setting Key: " + key + " to " + appSettings[key]);
          }
        }

        const doits = importDoits;
        await setDoits(doits);
        console.log(doits);

        const interactions = importInteractions;
        await setInteractions(interactions);
        console.log(interactions);

        setImportedData('');

        alert("Import successful!")
      }, 1000);
    } catch (e) {
      alert("Invalid JSON data");
      return;
    }
  }

  const handleSnoozeChange = (event: any) => {
    const newAppSettings = {
      ...appSettings,
      doitCompletionSnooze: event.target.value
    };
    setAppSettings(newAppSettings);
    saveAppSettings(newAppSettings);
  };

  const handleDeveloperModeChange = (event: any) => {
    const newAppSettings = {
      ...appSettings,
      developerMode: event.target.value
    };
    setAppSettings(newAppSettings);
    saveAppSettings(newAppSettings);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
      <Box sx={{ width: '80%', maxWidth: '700px'}}>
        <Typography sx={{mt: 2, userSelect: 'none'}} variant="h2" gutterBottom onClick={() => setShowDeveloperMode(showDeveloperMode + 1)}>
          App Settings
        </Typography>
        <Divider sx={{ my: 2}} />
        <Typography variant="h4" sx={{ mt: 4, mb: 1}}>
          Default Didit Completion
        </Typography>
        <Typography variant="body1" sx={{ mb: 2}}>
          When a doit is completed, it will automatically snooze for a certain amount of time.
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="completion-snooze-label">Default Doit Completion</InputLabel>
          <Select
            labelId="completion-snooze-label"
            id="completion-snooze-select"
            value={appSettings.doitCompletionSnooze}
            label="Default Doit Completion"
            onChange={handleSnoozeChange}
          >
            <MenuItem value={0}>No snooze</MenuItem>
            <MenuItem value={1}>1 hour</MenuItem>
            <MenuItem value={1}>3 hour</MenuItem>
            <MenuItem value={1}>6 hour</MenuItem>
            <MenuItem value={12}>12 hours</MenuItem>
            <MenuItem value={24}>24 hours</MenuItem>
            <MenuItem value={24*2}>2 days</MenuItem>
            <MenuItem value={24*3}>3 days</MenuItem>
            <MenuItem value={24*7}>1 week</MenuItem>
          </Select>
        </FormControl>
        { showDeveloperMode % 5 == 0 && <>
          <Typography variant="h4" sx={{ mt: 4, mb: 1}}>
            Developer Mode
          </Typography>
          <Typography variant="body1" sx={{ mb: 2}}>
            Shows some debug info on the sidebar
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="completion-dev-label">Developer Mode</InputLabel>
            <Select
              labelId="completion-dev-label"
              id="completion-dev-select"
              value={appSettings.developerMode}
              label="Default Doit Completion"
              onChange={handleDeveloperModeChange}
            >
              <MenuItem value={0}>No</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="h4" sx={{ mt: 4, mb: 1}}>
            Export Data
          </Typography>
          <Typography variant="body1" sx={{ mb: 2}}>
            You can export your data here by copying and pasting the data else.
          </Typography>
          <TextField
            margin="dense"
            id="displayExport"
            name="displayExport"
            label="Exported Data" 
            placeholder="Press export to generate the data"
            autoComplete="off"
            minRows={1} maxRows={4}
            value={displayExport}
            multiline fullWidth
            variant="filled"
          />
          <Button color="primary" onClick={() => exportData() } fullWidth variant="outlined">Export</Button>
          { displayExport && 
          <>
            <Typography variant="body1" sx={{ mb: 2}}>
              You can send this to yourself to import on another device.<br/>
              <a href="https://pastebin.com">Pastebin.com</a> is a good place to store it.
            </Typography>
          </> }
          <Divider sx={{ my: 2}} />
          <Typography variant="h4" sx={{ mt: 4, mb: 1}}>
            Import Data
          </Typography>
          <Typography variant="body1" sx={{ mb: 2}}>
            You can import your data here by pasting the data you exported. All three must be filled to import the data.
          </Typography>
          <TextField
            margin="dense"
            id="localStorage"
            name="localStorage"
            label="Local Storage Data" 
            placeholder="Paste the string you saved here"
            autoComplete="off"
            minRows={1} maxRows={4}
            value={importedData}
            multiline fullWidth
            variant="standard"
            onChange={(event) => setImportedData(event.target.value)}
          />
          <Button color="primary" onClick={() => importData() } fullWidth variant="outlined">Import</Button>
          <Typography variant="body1" sx={{ mb: 2}}>
            <b>Warning:</b> Importing data will wipe out your existing data. Make sure you have a backup of your data before importing.
          </Typography>
        </> }
      </Box>
    </Box>
    
  );
};

export default AppSettings;
