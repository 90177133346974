import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { sortBy } from 'lodash';
import { Interaction } from '../pages/AppContainer';
import InteractionDay from '../views/InteractionDay';
export interface InteractionListProps {
  interactions: Interaction[];
}

export interface InteractionFlags {
  name: string;
  didit: boolean;
  latered: boolean;
  snoozed: boolean;
}
export interface InteractionDayMetadata {
  date: string;
  didit: number;
  latered: number;
  snoozed: number;
  interactions: { [key:string]: InteractionFlags };
}

const InteractionDayList = (props: InteractionListProps) => {
  // Put interactions into their respective days and filter out the interactions we want
  const { interactions } = props;
  const interactionDays: { [key: string]: InteractionDayMetadata } = {};
  interactions.forEach((interaction) => {
    const date = interaction.forDate;
    const viableInteractions = ['didit', 'didntit', 'later', 'snooze']
    if (!viableInteractions.includes(interaction.type)) {
      return;
    }
    if (!interactionDays[date]) {
      const interactionDayMetadata = {
        date,
        didit: 0,
        latered: 0,
        snoozed: 0,
        interactions: {},
      }
      interactionDays[date] = interactionDayMetadata;
    }

    if (!interactionDays[date].interactions[interaction.doitLocalId]) {
      interactionDays[date].interactions[interaction.doitLocalId] = {
        name: interaction.doitName,
        didit: false,
        latered: false,
        snoozed: false,
      }
    }

    if (interaction.type === 'didit' && interactionDays[date].interactions[interaction.doitLocalId].didit === false) {
      interactionDays[date].didit += 1;
      interactionDays[date].interactions[interaction.doitLocalId].didit = true;
    }

    if ((interaction.type === 'didntit' || interaction.type === 'later') && interactionDays[date].interactions[interaction.doitLocalId].latered === false) {
      interactionDays[date].latered += 1;
      interactionDays[date].interactions[interaction.doitLocalId].latered = true;
    }

    if (interaction.type === 'snooze' && interactionDays[date].interactions[interaction.doitLocalId].snoozed === false) {
      interactionDays[date].snoozed += 1;
      interactionDays[date].interactions[interaction.doitLocalId].snoozed = true;
    }
  });

  const interactionDayValues = sortBy(Object.values(interactionDays), (interactionDay: InteractionDayMetadata) => interactionDay.date).reverse();
  return (
    <>
      {(!props.interactions || props.interactions.length === 0) && (
        <Box sx={{my:3}}>
          <Typography variant="h5" gutterBottom>
            You have not done anything yet
          </Typography>
          <Typography variant="body2" gutterBottom>
            (Come back again when you've procrastinated a bit more)
          </Typography>
          <Divider sx={{ my: 3 }}/>
          <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
            Go to the <EmojiObjectsIcon fontSize="small"/> page for inspiration.
          </Typography>
        </Box>
      )}
      {props.interactions && (
        <Box>
          {interactionDayValues.map((interactionDay: InteractionDayMetadata) => (
            <InteractionDay key={interactionDay.date} interactionDayMetadata={interactionDay} />
          ))}
        </Box>
      )}
    </>
  )
}

export default InteractionDayList;