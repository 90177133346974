import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import AppContext from './helpers/AppContext';
import getAppText from './helpers/AppText';
import { addNewTags, getAvailableTags } from './helpers/Database';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


function StateProvider() {
  const [loggedIn, setLoggedIn] = useState(false); // This is used to modify the top bar.
  const [offlineMode, setOfflineMode] = useState(false); // This is used to modify the top bar.
  const [username, setUsername] = useState(''); // This stores the username of the logged in user
  const [changeCount, setChangeCount] = useState(0); // This is a temp variable.
  const [backdateDoit, setBackdateDoit] = useState({localId: '', action: ''}); // This is used to backdate a doit.
  const [editDoit, setEditDoit] = useState(''); // This is used to edit a doit.
  const [update, setUpdate] = useState(false); // This is used to trigger useEffect whereever the list needs to be updated.
  const [procrastinateMode, setProcrastinateMode] = useState(true); // This is used for the welcome screen to show the different selling text.
  const [snackbar, setSnackbar] = useState({open: false, message: '', severity: 'success'}); // This is used to show a snackbar message.
  const [devStats, setDevStats] = useState({totalDoits: 0, totalInteractions: 0}); // This is used to show developer stats
  const userSettings = {
    loggedIn, setLoggedIn,
    offlineMode, setOfflineMode,
    username, setUsername,
    changeCount, setChangeCount,
    backdateDoit, setBackdateDoit,
    editDoit, setEditDoit,
    update, setUpdate,
    procrastinateMode, setProcrastinateMode,
    getAppText,
    snackbar, setSnackbar,
    devStats, setDevStats
  };
  
  useEffect(() => {
    console.log("Setting tags");
    const availableTags = [
      "exercise",
      "work",
      "school",
      "chores",
      "music",
      "art",
      "housework",
    ];

    const tags = getAvailableTags();
    if (tags.length === 0) {
      addNewTags(availableTags);
    }
  }, []);

  return (
    <AppContext.Provider value={userSettings}>
      <App />
    </AppContext.Provider>
  )
}
root.render(
  <React.StrictMode>
    <StateProvider />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
