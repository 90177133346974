import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { Badge, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { useContext } from 'react';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import AppContext from '../helpers/AppContext';

const SyncStatus = (props: any) => {
  const { loggedIn, offlineMode, changeCount, getAppText } = useContext(AppContext);

  return (
    <Box {...props}>
      {
        offlineMode && (
          <Box sx={{ ml: 1}}>
            <Chip label="offline" size="small" variant="outlined" sx={{ mt:-1, p:0, mr: 1.5, color: 'white'}} />
            <CloudOffIcon fontSize='small' sx={{ color: 'white' }} />
          </Box>
        )
      }
      { !offlineMode && loggedIn && changeCount > 0 &&
        <Box sx={{ ml: 1}}>
          <Chip label="syncing" size="small" color="success" variant="outlined"/>
          <Badge sx={{ml: 1}} badgeContent={changeCount} max={999} color="success">
            <CloudSyncOutlinedIcon fontSize='small' sx={{ color: 'white' }} />
          </Badge>
        </Box>
      }
      { !offlineMode && loggedIn && changeCount === 0 &&
        <Box sx={{ ml: 1}}>
          <Chip label="online" size="small" color="success" variant="outlined"/>
          <Badge sx={{ml: 1}} badgeContent={0} max={999} color="success">
            <CloudDoneOutlinedIcon fontSize='small' color="success" />
          </Badge>
        </Box>
      }
    </Box>
  );
}; 

export default SyncStatus;