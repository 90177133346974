import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { useCallback, useEffect, useState } from "react";
import { getDoits, getFavouriteDoitIds, toggleFavoriteDoit } from "../helpers/Database";
import { filterActiveDoits } from "../helpers/Filters";
import { sortDoitByName } from "../helpers/Sorts";
import { Interaction } from "../pages/AppContainer";
import InteractionDoit from "../views/InteractionDoit";

export interface InteractionDoitListProps {
  interactions: Interaction[];
}

export interface CondensedInteraction {
  date: string;
  type: string;
  count: number;
  doitLocalId: string;
}

export interface InteractionDoitCondensed {
  doitId: string;
  title: string;
  favourite: boolean;
  lastDone: string|null;
  lastSkipped: string|null;
  interactions: CondensedInteraction[];
}

const InteractionDoitList = (props: InteractionDoitListProps) => {
  const { interactions } = props;
  const [ loading , setLoading ] = useState(true);
  const [ doits, setDoits ] = useState<InteractionDoitCondensed[]>([]);
  const [ timespan, setTimespan ] = useState('d7');
  const handleTimespanChange = (e:any) => {
    setTimespan(e.target.value);
  }
  
  const [ favourites, setFavourites ] = useState(getFavouriteDoitIds());
  const handleFavouriteClick = (e:any, doitId:string) => {
    e.stopPropagation();
    setFavourites(toggleFavoriteDoit(doitId));
  }

  const loadDoits = useCallback(async () => {
    let dits = await getDoits();
    dits = filterActiveDoits(dits);
    dits = sortDoitByName(dits);

    const interactionMap:any = {'didit': 'didit', 'didntit': 'later', 'later': 'later', 'snooze': 'snooze'}
    const viableInteractions = Object.keys(interactionMap);

    const filteredInteractions = interactions.filter((interaction) => {
      return viableInteractions.includes(interaction.type);
    });

    const condensedInteractions:CondensedInteraction[] = filteredInteractions.map((interaction) => {
      return {
        type: interactionMap[interaction.type],
        date: interaction.forDate,
        doitLocalId: interaction.doitLocalId,
        count: interaction.count,
      }
    });
    console.log('condensedInteractions', condensedInteractions);

    let doitCollection:InteractionDoitCondensed[] = dits.map ((d) => {
      return {
        doitId: d.localId,
        title: d.name,
        favourite: favourites.includes(d.localId),
        lastDone: d.lastDoneAt ? formatDistanceToNowStrict(new Date(d.lastDoneAt)) : null,
        lastSkipped: d.lastSkippedAt ? formatDistanceToNowStrict(new Date(d.lastSkippedAt)) : null,
        interactions: condensedInteractions.filter((i) => i.doitLocalId === d.localId),
      }
    })
    
    console.log(doitCollection);
    setDoits(doitCollection);
    setLoading(false);
  }, [interactions, favourites]);

  useEffect(() => {
    loadDoits();
  }, [interactions, loadDoits]);

  return (
    <Box>
      <Typography variant="caption">
        Display
      </Typography>
      <ToggleButtonGroup
        value={timespan}
        exclusive
        size="small"
        disabled={loading}
        onChange={handleTimespanChange}
        aria-label="change timespan"
        sx = {{ mx: 2, my: 1 }}
      >
        <ToggleButton value="d7" aria-label="7 days"> 7 days </ToggleButton>
        <ToggleButton value="d14" aria-label="14 days"> 14 days </ToggleButton>
        <ToggleButton value="d28" aria-label="28 days"> 28 days </ToggleButton>
        {/* <ToggleButton value="w" aria-label="8 weeks"> 8 weeks </ToggleButton>
        <ToggleButton value="m" aria-label="3 months"> 12 months </ToggleButton>
        <ToggleButton value="y" aria-label="1 year"> 1 year </ToggleButton> */}
      </ToggleButtonGroup>
      {loading ? (
        <Typography variant="h5" sx={{ m:2 }}>
          Loading...
        </Typography>
      ) : (
        doits.map((doit) => 
          <InteractionDoit
            interactionDoit={doit}
            handleFavouriteClick={handleFavouriteClick}
            timespan={timespan}
            favourite={doit.favourite} />
        )
      )}
      
    </Box>
  );
};


export default InteractionDoitList;