import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getAvailableTags, getDoits, getFavouriteDoitIds, toggleFavoriteTag } from "../helpers/Database";
import { Interaction } from "../pages/AppContainer";
import InteractionTags from "../views/InteractionTag";

export interface InteractionTagsListProps {
  interactions: Interaction[];
}

export interface CondensedInteraction {
  date: string;
  type: string;
  count: number;
  tags: string[];
}

export interface InteractionTagCondensed {
  tag: string;
  favourite: boolean;
  interactions: CondensedInteraction[];
}

const InteractionTagsList = (props: InteractionTagsListProps) => {
  const { interactions } = props;
  const [ loading , setLoading ] = useState(true);
  const [ tags, setTags ] = useState<string[]>(getAvailableTags());
  const [ tagGroups, setTagGroups ] = useState<InteractionTagCondensed[]>([]);

  const [ timespan, setTimespan ] = useState('d7');
  const handleTimespanChange = (e:any) => {
    setTimespan(e.target.value);
  }
  
  const [ favourites, setFavourites ] = useState(getFavouriteDoitIds());
  const handleFavouriteClick = (e:any, tag:string) => {
    e.stopPropagation();
    setFavourites(toggleFavoriteTag(tag));
  }

  const loadDoits = useCallback(async () => {
    let dits = await getDoits();

    const interactionMap:any = {'didit': 'didit', 'didntit': 'later', 'later': 'later', 'snooze': 'snooze'}
    const viableInteractions = Object.keys(interactionMap);

    const filteredInteractions = interactions.filter((interaction) => {
      return viableInteractions.includes(interaction.type);
    });

    // Join the tags to the interactions via the doit table
    const condensedInteractions:CondensedInteraction[] = filteredInteractions.map((interaction) => {
      return {
        type: interactionMap[interaction.type],
        date: interaction.forDate,
        count: interaction.count,
        tags: dits.filter((doit) => doit.localId === interaction.doitLocalId)[0].tags
      }
    });

    // Map the interactions to the tags
    const condensedTags:InteractionTagCondensed[] = tags.map((tag) => {
      return {
        tag,
        favourite: favourites.includes(tag),
        interactions: condensedInteractions.filter((interaction) => interaction.tags.includes(tag))
      }
    }).sort((a, b) => {
      if (a.favourite && !b.favourite) return -1;
      else if (!a.favourite && b.favourite) return 1;
      return 0;
    });

    setTagGroups(condensedTags);
    setLoading(false);
  }, [interactions, tags, favourites]);

  useEffect(() => {
    loadDoits();
  } , [loadDoits]);

  return (
    <Box>
      <Typography variant="caption">
        Display
      </Typography>
      <ToggleButtonGroup
        value={timespan}
        exclusive
        size="small"
        disabled={loading}
        onChange={handleTimespanChange}
        aria-label="change timespan"
        sx = {{ mx: 2, my: 1 }}
      >
        <ToggleButton value="d7" aria-label="7 days"> 7 days </ToggleButton>
        <ToggleButton value="d14" aria-label="14 days"> 14 days </ToggleButton>
        <ToggleButton value="d28" aria-label="28 days"> 28 days </ToggleButton>
        {/* <ToggleButton value="w" aria-label="8 weeks"> 8 weeks </ToggleButton>
        <ToggleButton value="m" aria-label="3 months"> 12 months </ToggleButton>
        <ToggleButton value="y" aria-label="1 year"> 1 year </ToggleButton> */}
      </ToggleButtonGroup>
      {loading ? (
        <Typography variant="h5" sx={{ m:2 }}>
          Loading...
        </Typography>
      ) : (
        tagGroups.map((tagGroup) => 
          <InteractionTags
            interactionTagGroup={tagGroup}
            handleFavouriteClick={handleFavouriteClick}
            timespan={timespan}
            favourite={tagGroup.favourite} />
        )
      )}
      
    </Box>
  );
};

export default InteractionTagsList;