import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { addInteraction, completeDoit, getDoit } from "../helpers/Database";
import { Doit } from "../pages/AppContainer";

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HotelIcon from '@mui/icons-material/Hotel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { addDays, format, formatISO9075 } from "date-fns";
import AppContext from "../helpers/AppContext";
import Typography from "./Typography";

export interface BackdateModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  backdateDoit?: {localId: string, action: string};
}

const BackdateModal = (props: BackdateModalProps) => {
  const { open, setOpen, backdateDoit } = props;
  const { setSnackbar } = useContext(AppContext);

  const [date, setDate] = useState<null|Date>(new Date());
  const [doit, setDoit] = useState<Doit|undefined>(undefined);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("backdateDoit", backdateDoit);
    if (backdateDoit?.localId) {
      getDoit(backdateDoit.localId).then((doit) => {
        setDoit(doit);
      });
    }
  }, [backdateDoit]);

  const handleBackdate = (e: FormEvent) => {
    e.preventDefault();
    if (date && doit) {
      if (backdateDoit?.action === "didit") {
        addInteraction(doit, "didit", formatISO9075(date, { representation: "date" }));
        if (doit.type === 'once') {
          completeDoit(doit, true);
          setSnackbar({ open: true, message: `Didit added on ${formatISO9075(date)} and doit archived`, severity: "info" });
        } else {
          setSnackbar({ open: true, message: `Didit added on ${formatISO9075(date)}`, severity: "info" });
        }
      } else if (backdateDoit?.action === "later") {
        addInteraction(doit, "later", formatISO9075(date, { representation: "date" }));
        setSnackbar({ open: true, message: `Later added on ${formatISO9075(date)}`, severity: "info" });
      }
    }
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleBackdate}>
        <DialogTitle>{"Backdate"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="info" sx={{ mb: 2 }}>
              <AlertTitle>How does backdating work?</AlertTitle>
              <Box component="ul" sx={{m: 0, paddingInlineStart: '1.2em'}}>
                <li>I see you've procrastinated logging your data</li>
                <li>This allows you to log a <strong>didit</strong> or <strong>later</strong> in the past</li>
                <li>You won't get points or a streak for this though. :/</li>
              </Box>
            </Alert>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                showDaysOutsideCurrentMonth={true}
                minDate={new Date(2010, 0, 1)}
                maxDate={addDays(new Date(), -1)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Typography variant="h5" sx={{ my: 0.5, textAlign:'center', verticalAlign: 'middle' }}>
              Adding a <strong>{backdateDoit?.action}</strong> {backdateDoit?.action === 'didit' ? <DirectionsRunIcon fontSize="small" /> : <HotelIcon fontSize="small"/>}
            </Typography>
            <Typography variant="h5" sx={{ my: 0.5, textAlign:'center', verticalAlign: 'middle' }}>
              to <strong style={{ textTransform:'uppercase'}}>{doit?.name}</strong>
            </Typography>
            <Typography variant="h5" sx={{ my: 0.5, textAlign:'center', verticalAlign: 'middle' }}>
              on <strong>{format(date!, 'EEE, d MMM yyyy') }</strong>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Backdate</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default BackdateModal;