import AddIcon from '@mui/icons-material/Add';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Alert, AlertColor, BottomNavigation, BottomNavigationAction, Fab, Paper, Slide, Snackbar } from "@mui/material";
import { gql, GraphQLClient } from 'graphql-request';
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AddDoitModal from "../components/AddDoitModal";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FirstTimeModal from '../components/FirstTimeModal';
import AppContext from "../helpers/AppContext";
import { addDoit, addNewTags, checkDatabaseVersion, updateDoit } from "../helpers/Database";
import { addLoginStat, checkAllStreakableStats } from '../helpers/Stats';
import useInterval from "../helpers/useInterval";

import Box from '@mui/material/Box';
import AppCopyright from '../components/AppCopyright';
import BackdateModal from '../components/BackdateModal';
import ChangelogModal from '../components/ChangelogModal';
import AppAppBar from '../views/AppAppBar';
import AppDrawer from '../views/AppDrawer';
import styled from '@emotion/styled';

const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  bottom: 85px !important;
  width: calc(100% - 100px);
  maxWidth: 500;
  z-index: 9999;
`
// Used for creating doits without all the extra fields
export interface DoitLite {
  name: string;
  type: 'once' | 'recurring';
  description: string;
  tags: string[];
}

export type DoitStatusType = "active" | "completed" | "deleted";
export interface Doit {
  localId: string;
  remoteId: string | null;
  name: string;
  type: 'once' | 'recurring';
  description: string;
  tags: string[];
  status: string;
  synced: boolean;
  snoozeTill: number | null;
  lastInteractionAt: number | null;
  lastDoneAt: number | null;
  lastSkippedAt: number | null;
  createdAt: number;
  updatedAt: number;
}

export interface DoitWithMeta extends Doit {
  favourite: boolean;
}

export type InteractionType = "doit" | "didit" | "didntit" | "later" | "snooze" | "unsnooze" | "completed";
export interface Interaction {
  localId: string;
  remoteId: string | null;
  doitLocalId: string;
  doitName: string;
  count: number;
  type: InteractionType;
  forDate: string;
  createdDate: string;
  createdAt: number;
  updatedAt: number;
  synced: boolean;
}

function SlideRight(props: any) {
  return <Slide {...props} direction="right" />;
}


const drawerWidth = 220;

const AppContainer = () => {
  const navigate = useNavigate();
  const { setLoggedIn, setOfflineMode, setUsername, setUpdate, backdateDoit, editDoit, setEditDoit, snackbar, setSnackbar } = React.useContext(AppContext);
  const [addOpen, setAddOpen] = React.useState(false);
  const [backdateOpen, setBackdateOpen] = React.useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  

  const [navPage, setnavPage] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log("Setting Nav Value", newValue);
    setnavPage(parseInt(newValue));
  };

  const location = useLocation();

  const addDoitCallback = async (formData: DoitLite) => {
    console.log("Add doit callback from application", formData);
    addNewTags(formData.tags);
    await addDoit(formData);
    setSnackbar({ open: true, message: "Doit added", severity: "info" });
    setUpdate((update) => !update);
  };

  const editDoitCallback = async (doitLocalId:string, formData: DoitLite) => {
    console.log("Edit doit callback from application", doitLocalId, formData);
    addNewTags(formData.tags);
    await updateDoit(doitLocalId, formData);
    setSnackbar({ open: true, message: "Doit updated", severity: "info" });
    setUpdate((update) => !update);
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const [nextSync, setNextSync] = useState(0);
  useInterval(() => {
    if (nextSync <= 0) {
      setNextSync(5);
    } else {
      setNextSync(nextSync - 1);
    }
  }, 1000);

  useEffect(() => {
    if (!!backdateDoit?.localId) {
      setBackdateOpen(true);
    }
  }, [backdateDoit]);

  useEffect(() => {
    if (editDoit) {
      setAddOpen(true);
    }
  }, [editDoit]);

  useEffect(() => {
    addLoginStat();
    checkAllStreakableStats();
    checkDatabaseVersion();
  }, []);

  useEffect(() => {
    if (location.pathname === '/app') {
      setnavPage(0);
    } else if (location.pathname === '/app/list') {
      setnavPage(1);
    } else if (location.pathname === '/app/history') {
      setnavPage(2);
    }
    if (!['/app', '/app/list', '/app/history'].includes(location.pathname)) {
      setnavPage(-1);
    }
  }, [navigate])

  useEffect(() => {
    console.log("Exucute useEffect");
    console.log(location.pathname);
    setLoggedIn(true);
    const authToken = localStorage.getItem("authToken");
    const offlineMode = localStorage.getItem("offlineMode");
    if (offlineMode === "true") {
      setOfflineMode(true);
    } else {
      setOfflineMode(false);
    }
    if (offlineMode === 'false') {
      if (authToken) { // Redirect users out if they aren't authenticated
        try {
          const query = gql`
            {
              username
            }
          `;
          const url:string = process.env.REACT_APP_GRAPHQL_SERVER_URL || "";
          const client = new GraphQLClient(url, {
            headers: {
              authorization: `Bearer ${authToken}`,
            },
          });
          client.request(query).then((data) => {
            console.log(data);
            setUsername(data.username);
          }).catch((error) => {
            console.log(error);
            navigate("/signin");
          });
        } catch (error) {
          console.log(error);
          navigate("/signin");
        }
      } else {
        navigate("/");
      }
    }
  }, [navigate]);
  
  return <Box sx={{display: 'flex'}}>
    <AppDrawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth}/>
    <Box component="main"
        sx={{ flexGrow: 1, p:0, width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
    >
      <AppAppBar onDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth}/>
      { (['/app', '/app/list'].includes(location.pathname)) &&
        <>
          <Fab
            color="primary" aria-label="add"
            onClick={() => {
              handleAddOpen();
            }}
            sx={{ position: 'fixed', bottom: 80, right: 25 }}
          >
            <AddIcon />
          </Fab>
        </>
      }
      <ChangelogModal />
      <FirstTimeModal />
      <Outlet />
      <Box sx={{ height: '80px' }} />
      <AddDoitModal
        open={addOpen}
        editDoit={editDoit}
        setOpen={setAddOpen}
        setEditDoit={setEditDoit}
        addDoitCallback={addDoitCallback}
        editDoitCallback={editDoitCallback}
        />
      <BackdateModal
        open={backdateOpen}
        setOpen={setBackdateOpen}
        backdateDoit={backdateDoit}
      />

      <StyledSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbar.open}
        TransitionComponent={SlideRight}
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
        sx={{ position: 'fixed', bottom: '300px', width: "calc(100% - 100px)", maxWidth: 500}}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity as AlertColor}
          sx={{ width: "100%", boxShadow:"1px 2px 3px 3px rgba(0, 0, 0, 0.05)" }}>
          {snackbar.message}
        </Alert>
      </StyledSnackbar>

      <Box sx={{ display: {md: 'none'}}}>
        <AppCopyright />
      </Box>
      
      <Paper sx={{ position: 'fixed', bottom: 0, left: { xs: 0, md: `${drawerWidth}px` }, right: 0, zIndex:9999 }} elevation={3}>
        <BottomNavigation
          value={navPage}
          onChange={handleChange}
        >
          <BottomNavigationAction label="What To Do" icon={<EmojiObjectsIcon />}
            onClick={() => {
              navigate("/app");
            }}
          />
          <BottomNavigationAction label="All Doits" icon={<ListAltIcon />}
            onClick={() => {
              navigate("/app/list");
            }}/>
          <BottomNavigationAction label="What I Did" icon={<TimelineIcon />}
            onClick={() => {
              navigate("/app/history");
            }}/>
          <BottomNavigationAction label="Badges" icon={<EmojiEventsIcon />}
            onClick={() => {
              navigate("/app/badges");
            }}/>
        </BottomNavigation>
      </Paper>
    </Box>
  </Box>;
};

export default AppContainer;