import { Link } from '@mui/material';
import Container from '@mui/material/Container';
import { useContext } from 'react';
import Typography from '../components/Typography';
import AppContext from '../helpers/AppContext';

export default function AppFooter() {
  const { loggedIn } = useContext(AppContext);

  return (
    <>
      {!loggedIn && 
        <Typography
          component="footer"
          sx={{
            py: 1,
            px: 2,
            mt: 'auto',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'primary.light'
          }}
        >
          <Container sx={{ my: 2, alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://doitlater.app/">
                Doitlater
              </Link>{' '}
              {new Date().getFullYear()}
              {'. '}
              {'Made haphazardly by '}
              <Link color="inherit" href="https://github.com/cjx3711">
                @cjx3711
              </Link>
            </Typography>
          </Container>
        </Typography>
      }
    </>
  );
}
