import {useContext} from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import AppContext from '../helpers/AppContext';

const backgroundImage =
  'https://images.unsplash.com/photo-1496814795703-e5b242546673?auto=format&fit=crop&w=1400';

export default function ProductHero() {
  const { procrastinateMode, getAppText } = useContext(AppContext);

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography
        color="inherit" align="center" variant="h1" marked="center"
        fontSize={{xs: "2.5rem", sm: "3.25rem", md: "4rem"}}>
        { getAppText('welcome_hero_title', procrastinateMode) }
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h4"
        sx={{ mb: { xs: 5, sm: 10 }, mt: { xs: 2, sm: 3 } }}
      >
        { getAppText('welcome_hero_subtitle', procrastinateMode) }
      </Typography>
      <Button
        color="primary"
        variant="contained"
        size="large"
        component="a"
        href="/#"
        disabled
        sx={{ minWidth: 200}}
      >
        { getAppText('welcome_hero_signup', procrastinateMode) }
      </Button>
      <Typography variant="subtitle2" color="inherit"  sx={{ minWidth: 200, mb: { xs: 1, sm: 2 } }}>
      { getAppText('welcome_hero_signupSubtitle', procrastinateMode) }

      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        component="a"
        href="/offline"
        sx={{ minWidth: 200 }}
      >
        { getAppText('welcome_hero_local', procrastinateMode) }

      </Button>
      <Typography variant="subtitle2" color="inherit">
        { getAppText('welcome_hero_localSubtitle', procrastinateMode) }
      </Typography>
    </ProductHeroLayout>
  );
}
