import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as React from 'react';
import AppContext from "../helpers/AppContext";
import ProductHero from "../views/ProductHero";
import ProductFAQ from "../views/ProductFAQ";
import ProductValues from "../views/ProductValues";
import ProductScreenshots from "../views/ProductScreenshots";
import PublicAppBar from "../views/PublicAppBar";

const Welcome = () => {
  const navigate = useNavigate();
  const { setLoggedIn } = React.useContext(AppContext);

  useEffect(() => {
    setLoggedIn(false);
    if (localStorage.getItem("authToken")) {
      navigate("/app");
    }
  }, [navigate, setLoggedIn]);
  
  return (
    <React.Fragment>
      <PublicAppBar showBanner={true} />
      <ProductHero />
      <ProductValues />
      <ProductScreenshots />
      <ProductFAQ />
    </React.Fragment>
  );
};

export default Welcome;