import React from "react";

export interface UserSettingsInterface {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
  offlineMode: boolean;
  setOfflineMode: (offlineMode: boolean) => void;
  username: string;
  setUsername: (username: string) => void;
  changeCount: number;
  setChangeCount: (changeCount: number) => void;
  backdateDoit: { localId: string; action: string;}
  setBackdateDoit: (backdateDoit: { localId: string; action: string;}) => void;
  editDoit: string;
  setEditDoit: (editDoit: string) => any;
  update: boolean;
  setUpdate: (callback: (value: boolean) => boolean) => any;
  procrastinateMode: boolean;
  setProcrastinateMode: (procrastinateMode: boolean) => void;
  getAppText: (key: string, procrastinateMode:boolean) => any;
  snackbar: {
    open: boolean;
    message: string;
    severity: string;
  };
  setSnackbar: (snackbar: {
    open: boolean;
    message: string;
    severity: string;
  }) => void;
  devStats: {
    totalDoits: number;
    totalInteractions: number;
  };
  setDevStats: (devStats: {
    totalDoits: number;
    totalInteractions: number;
  }) => void;
}
const userSettings:UserSettingsInterface = {
  loggedIn: false,
  setLoggedIn: (value: boolean) => {},
  offlineMode: false,
  setOfflineMode: (value: boolean) => {},
  username: "",
  setUsername: (value: string) => {},
  changeCount: 0,
  setChangeCount: (value: number) => {},
  backdateDoit: { localId: "", action: "" },
  setBackdateDoit: (value: { localId: string; action: string; }) => {},
  editDoit: "",
  setEditDoit: (value: string) => {},
  update: false,
  setUpdate:(callback: (value: boolean) => boolean) => {},
  procrastinateMode: false,
  setProcrastinateMode: (value: boolean) => {},
  getAppText: (key: string, procrastinateMode:boolean = true) => {
    return "";
  },
  snackbar: {
    open: false,
    message: "",
    severity: "success",
  },
  setSnackbar: (snackbar: {
    open: boolean;
    message: string;
    severity: string;
  }) => {},
  devStats: {
    totalDoits: 0,
    totalInteractions: 0,
  },
  setDevStats: (devStats: {
    totalDoits: number;
    totalInteractions: number;
  }) => {},
}
const AppContext = React.createContext(userSettings);

export default AppContext;