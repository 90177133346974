import { Box, List } from "@mui/material";
import React, { useState } from "react";
import { getFavouriteDoitIds, toggleFavoriteDoit } from "../helpers/Database";
import { Doit, DoitWithMeta, InteractionType } from "../pages/AppContainer";
import DoitListTableRow from './DoitListTableRow';


export interface DoitListTableProps {
  doits: Doit[];
  currentDoitId: string | null;
  handleSettingsClick: (event: React.MouseEvent<HTMLElement>, doit: Doit) => void;
  handleInteraction: (doit: Doit, interaction: InteractionType) => void;
}

const DoitListTable = (props: DoitListTableProps) => {
  const { doits, currentDoitId, handleSettingsClick, handleInteraction } = props;

  const [ favourites, setFavourites ] = useState(getFavouriteDoitIds());
  const handleFavouriteClick = (doit: Doit) => {
    setFavourites(toggleFavoriteDoit(doit.localId));
  }

  // Map the favourites then move facourites to the top of the list
  const sortedDoits:DoitWithMeta[] = doits.map ((doit: Doit) => {
    return {
      favourite: favourites.includes(doit.localId),
      ...doit
    }
  }).sort((a, b) => {
    if (a.favourite && !b.favourite) return -1;
    if (!a.favourite && b.favourite) return 1;
    return 0;
  })
  

  return (
    <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', bgcolor: 'background.paper' }}>
      <List
        sx={{ width: '100%', maxWidth: 600, mx: 'auto', bgcolor: 'background.paper' }}
        aria-labelledby="nested-list-subheader"
      >
        { sortedDoits.map((doit: DoitWithMeta, index) => (
          <DoitListTableRow
          key={index}
          doit={doit}
          currentDoitId={currentDoitId}
          handleFavouriteClick={handleFavouriteClick}
          handleSettingsClick={handleSettingsClick}
          handleInteraction={handleInteraction} />
        )) }

      </List>
    </Box>
  );
};

export default DoitListTable;