import styled from '@emotion/styled';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import AppContext from '../helpers/AppContext';

const CustomLogoLink = styled(Link)`
  font-size: 24px;
  display: flex;
  align-items: center;
  user-select: none;
  transition: all 300ms;
  &:hover {
    opacity: 0.9;
  }
  img {
    height: 24px;
    margin-right: 7px;
  }
`
interface PublicAppBarProps {
  showBanner: boolean;
}

function PublicAppBar(props: PublicAppBarProps) {
  const { procrastinateMode, setProcrastinateMode, getAppText } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const clickProcrastinate = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    setProcrastinateMode(!procrastinateMode);
  }

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between', boxShadow:"0 -4px 6px 0 rgba(0, 0, 0, 0.1) inset"}}>
          <CustomLogoLink
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
          >
            <img src="/logo_white.png" alt="support"/> {getAppText('appTitle', procrastinateMode)}
          </CustomLogoLink>

          {/* { !loggedIn &&
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                href="/#"
                sx={rightLink}
              >
                {'Sign In'}
              </Link>
              <Link
                variant="h6"
                underline="none"
                href="/#"
                sx={{ ...rightLink, color: 'secondary.main' }}
              >
                {'Sign Up'}
              </Link>
            </Box>
          } */}
        </Toolbar>
        { props.showBanner &&
          <Box sx={{ backgroundColor: '#fff', boxShadow:"0 3px 6px 0 rgba(0, 0, 0, 0.1) ", justifyContent: 'space-between', textAlign: 'center', height: 37, minHeight: 37, p: 0 }}>
            <Button sx={{ width: '100%' }} color="secondary" onClick={() => clickProcrastinate()}>
              { getAppText('welcome_notice_buttonText', procrastinateMode) }
            </Button>
          </Box>
        }
      </AppBar>
      <Toolbar />
      { props.showBanner && <Box sx={{ height: 37, minHeight: 37 }} /> }
    </div>
  );
}

export default PublicAppBar;
