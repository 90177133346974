import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { useContext } from 'react';
import AppBar from '../components/AppBar';
import SyncStatus from '../components/SyncStatus';
import Toolbar from '../components/Toolbar';
import AppContext from '../helpers/AppContext';

const CustomLogoLink = styled(Link)`
  font-size: 24px;
  display: flex;
  align-items: center;
  user-select: none;
  transition: all 300ms;
  &:hover {
    opacity: 0.9;
  }
  img {
    height: 24px;
    margin-right: 7px;
  }
`
interface AppAppBarProps {
  onDrawerToggle: () => void;
  drawerWidth: number;
}

function AppAppBar(props: AppAppBarProps) {
  const { getAppText } = useContext(AppContext);

  return (
    <>
      <Box sx={{ height: '60px' }} />
      <AppBar position="fixed"
        sx={{
          height: '60px',
          left: { xs: 0, md: `${props.drawerWidth}px`},
          width: { md: `calc(100% - ${props.drawerWidth}px)` },
          boxShadow:"0 4px 6px 0 rgba(0, 0, 0, 0.1)" }}>
        <Toolbar sx={{ justifyContent: 'space-between'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => props.onDrawerToggle()}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <CustomLogoLink
            variant="h6"
            underline="none"
            color="inherit"
            href="/app"
          >
            <img src="/logo_white.png" alt="support"/> {getAppText('appTitle', true)}
          </CustomLogoLink>
          <SyncStatus sx={{ display: { sm: 'block', md: 'none' }}}/>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          </Box>
        </Toolbar>
      </AppBar>
    </>
    
  );
}

export default AppAppBar;
