import {useContext} from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Avatar } from '@mui/material';

import AppContext from '../helpers/AppContext';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  const { procrastinateMode, getAppText } = useContext(AppContext);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'primary.light' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: '70px', height: '70px'}}>
                { procrastinateMode ? <AccessTimeIcon fontSize="large"/> : <CalendarMonthIcon fontSize="large"/> }
              </Avatar>
              <Typography variant="h4" sx={{ my: 5 }}>
                { getAppText('welcome_values_title1', procrastinateMode) }
              </Typography>
              <Typography variant="body1">
                { getAppText('welcome_values_content1', procrastinateMode) }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: '70px', height: '70px'}}>
                <Filter9PlusIcon fontSize="large"/>
              </Avatar>
              <Typography variant="h4" sx={{ my: 5 }}>
                { getAppText('welcome_values_title2', procrastinateMode) }
              </Typography>
              <Typography variant="body1">
                { getAppText('welcome_values_content2', procrastinateMode) }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: '70px', height: '70px'}}>
                { procrastinateMode ? <NotificationsPausedIcon fontSize="large"/> : <TimelineIcon fontSize="large"/> }
              </Avatar>
              <Typography variant="h4" sx={{ my: 5 }}>
                { getAppText('welcome_values_title3', procrastinateMode) }
              </Typography>
              <Typography variant="body1">
                { getAppText('welcome_values_content3', procrastinateMode) }
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
