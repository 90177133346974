import {useContext} from 'react';
import AppContext from '../helpers/AppContext';
import Carousel from 'react-material-ui-carousel'
import { Box, Paper } from '@mui/material';
import Typography from '../components/Typography';

 
const images = [
  'app_screenshot.jpg',
  'app_screenshot.jpg',
  'app_screenshot.jpg',
  'app_screenshot.jpg',
];

export default function ProductScreenshots() {
  const { procrastinateMode, getAppText } = useContext(AppContext);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 6, width: '100%' }}>
      <Typography variant="h4" component="span" sx={{ mb: 3}}>
        { getAppText('welcome_screenshots_title', procrastinateMode) }
      </Typography>
      <Carousel animation="slide" sx={{width: '100%'}} cycleNavigation={true} fullHeightHover={true} changeOnFirstRender={true}>
        {
          images.map( (item, i) => <Paper key={i}>
            <img draggable="false" style={{ maxHeight: "500px" }} src={item} />
            <Typography variant="h5" component="h3">
              Some description here about the image
            </Typography>
          </Paper>
          )
        }
      </Carousel>
    </Box>
    
    
  )
};