import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, Collapse, Divider } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from "react";
import DoitSuggestion from "../components/DoitSuggestion";
import AppContext from "../helpers/AppContext";
import { getCurrentDoit, getDoits } from "../helpers/Database";
import { filterActiveDoits, filterUnsnoozedDoits } from "../helpers/Filters";
import { sortByLastInteraction } from "../helpers/Sorts";
import { Doit } from "./AppContainer";


const AppPageDoWhat = () => {
  const [doits, setDoits] = useState<Doit[]>([]);
  const [doit, setDoit] = useState<Doit|null>();
  const [haveSnoozed, setHaveSnoozed] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [endOfList, setEndOfList] = useState<boolean>(false);
  const [currentDoit, setCurrentDoit] = useState<Doit|null>();
  const { update, devStats, setDevStats } = React.useContext(AppContext);

  const updateDoits = useCallback(async () => {
    console.log("Updating doits");
    setEndOfList(false);
    setCurrentIndex(0);
    setCurrentDoit(await getCurrentDoit());
    const dits = await getDoits();
    setDevStats({
      ...devStats,
      totalDoits: dits.length,
    });
    let filteredDits = filterUnsnoozedDoits(filterActiveDoits(dits));
    setHaveSnoozed(filteredDits.length < dits.length);
    // Sort by last interaction
    filteredDits = sortByLastInteraction(filteredDits);
    setDoits(filteredDits);
    if (filteredDits.length > 0) { 
      setDoit(filteredDits[0]);
    } else {
      setDoit(null);
    }
  }, [devStats, setDevStats]);

  const nextDoitCallback = async () => {
    console.log("Later callback");
    if (currentIndex < doits.length - 1) {
      setDoit(doits[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    } else {
      setDoit(null);
      setEndOfList(true);
    }
  }

  useEffect(() => {
    updateDoits();
  }, [update]);

  return (
    <>
      <Collapse in={!!currentDoit}>
        <Box sx={{my:3}}>
          <Typography variant="h2" gutterBottom>
            Currently working on...
          </Typography>
          <Typography variant="h4">
            Did you do it?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', p:1, m: 1}}>
            { !!currentDoit && <DoitSuggestion doit={currentDoit!} doing={true} /> }
          </Box>
        </Box>
      </Collapse>
      <Collapse in={!currentDoit && !!doit}>
        <Box sx={{my:3}}>
          <Typography variant="h2" gutterBottom>
            What should I do?
          </Typography>
          <Typography variant="h4">
            How about I...
          </Typography>
          {/* <DoitSuggestion doit={doit!} nextDoitCallback={nextDoitCallback} /> */}
          <Box sx={{ display: 'flex', justifyContent: 'center', p:1, m: 1}}>
            <Box sx={{position: 'relative', width: {xs: 350, sm: 430, md: 500}}}>
              { doits.map((d, i) => {
                const j = i - currentIndex;
                const ox = 2;
                const oy = 7;
                if (i < currentIndex && j >= -2) {
                  return <DoitSuggestion sx={{ position: 'absolute', zIndex: 0, top:oy*j, left:ox*j, display: j<-2?'none':'block', opacity: 1+j*0.3, filter: `brightness(0.9)` }} key={i} doit={d!} nextDoitCallback={nextDoitCallback} />
                } else if (i === currentIndex) {
                  return <DoitSuggestion sx={{ position: 'absolute', zIndex: 1000 }} key={i} doit={d!} nextDoitCallback={nextDoitCallback} />
                } else if (i > currentIndex && j <= 5) {
                  return <DoitSuggestion sx={{ position: 'absolute', zIndex: 1000 - j, top:oy*j, left:ox*j, opacity:1-j*0.17, filter: `brightness(0.9)` }} key={i} doit={d!} disabled={true} nextDoitCallback={nextDoitCallback} />
                }
              })}
            </Box>
          </Box>
        </Box>
      </Collapse>
      <Collapse in={!currentDoit && !doit}>
        { !!endOfList ?
          <Box sx={{my:3}}>
            <Typography variant="h2" gutterBottom>
              What should I do?
            </Typography>
            <Typography variant="h4" gutterBottom>
              I have reached the end. :(
            </Typography>
            <Divider sx={{ my: 3 }}/>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
              Restart the list
            </Typography>
            <Typography variant="body1">
              <Button variant="contained" startIcon={<ReplayIcon />} size="large" onClick={updateDoits}>
                Again!
              </Button>
            </Typography>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
              Add a doit!
            </Typography>
            <Typography variant="body1">
              Click on the <AddIcon fontSize="small"/> botton in the bottom right corner.
            </Typography>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
              View all my doits!
            </Typography>
            <Typography variant="body1">
              Go to the <ListAltIcon fontSize="small"/> page on the bottom bar. 
            </Typography>
          </Box> :
          <Box sx={{my:3}}>
            <Typography variant="h2" gutterBottom>
              What should I do?
            </Typography>
            {
              haveSnoozed &&
              <>
                <Typography variant="h4" gutterBottom>
                  I'm out of things to do!
                </Typography>
                <Typography variant="body2" gutterBottom>
                  (The ones I snoozed are not included)
                </Typography>
              </>
            }
            {
              !haveSnoozed &&
              <>
                <Typography variant="h4" gutterBottom>
                  I have nothing to do!
                </Typography>
                <Typography variant="body2" gutterBottom>
                  (Really though?)
                </Typography>
              </>
            }
            <Divider sx={{ my: 3 }}/>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
              Add a doit!
            </Typography>
            <Typography variant="body1">
              Click on the <AddIcon fontSize="small"/> botton in the bottom right corner.
            </Typography>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
              View all my doits!
            </Typography>
            <Typography variant="body1">
              Go to the <ListAltIcon fontSize="small"/> page on the bottom bar. 
            </Typography>
          </Box>
        }
      </Collapse>
    </>
    
  )
}

export default AppPageDoWhat;