import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";


export const appVersion = '0.1.3';
export const updatedAppVersion = () => {
  const version = localStorage.getItem('appVersion');
  if (version == null) {
    localStorage.setItem('appVersion', appVersion);
    return false;
  } else if (version !== appVersion) {
    localStorage.setItem('appVersion', appVersion);
    return true;
  }
  return false;
}


const ChangelogModal = () => {
  const [show, setShow] = useState(false);

  useEffect (() => {
    if (updatedAppVersion()) {
      setShow(true);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog open={show} onClose={handleClose} >
      <DialogTitle sx={{py: 2, px: 6}}>
        <Typography variant="h4">App Updated!</Typography>
        <Typography variant="h5">What's new?</Typography>
        </DialogTitle>
      <DialogContent sx={{pt: 1, px: 6}}>
        <DialogContentText>
          <Box>
          <Typography variant="h6">v0.1.3 (22 Dec 2022)</Typography>
            <Typography variant="body1">
              <ul>
                <li>New app logo</li>
                <li>Recurring and one-time Doits</li>
              </ul>
            </Typography>
            <Typography variant="h6">v0.1.2 (21 Dec 2022)</Typography>
            <Typography variant="body1">
              <ul>
                <li>Change number of days shown in stats page</li>
              </ul>
            </Typography>
            <Typography variant="h6">&lt; v0.1.1 (20 Dec 2022)</Typography>
            <Typography variant="body1">
              <ul>
                <li>Added tag management</li>
                <li>Group history by tags</li>
                <li>Favourite doits and tags</li>
                <li>Added app versioning</li>
              </ul>
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Okay</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangelogModal;
 