import NoEncryptionOutlinedIcon from '@mui/icons-material/NoEncryptionOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { gql, GraphQLClient } from 'graphql-request';
import * as React from 'react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../helpers/AppContext";
import PublicAppBar from '../views/PublicAppBar';

export default function SignUp() {
  const navigate = useNavigate();
  const { setLoggedIn } = React.useContext(AppContext);

  useEffect(() => {
    setLoggedIn(false);
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      try {
        const query = gql`
          {
            username
          }
        `;
        const url:string = process.env.REACT_APP_GRAPHQL_SERVER_URL || "";
        const client = new GraphQLClient(url, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        client.request(query).then((data) => {
          console.log(data);
          navigate("/app");
        }).catch((error) => {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [navigate, setLoggedIn]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      username: data.get('username'),
      password: data.get('password'),
      passwordAgain: data.get('passwordAgain'),
    });

    if ( data.get('password') !== data.get('passwordAgain') ) {
      alert("Passwords do not match");
      return;
    };

    if ( data.get('username') === "" || data.get('password') === "" ) { 
      alert("Username or password is empty");
      return;
    };

    axios.post(process.env.REACT_APP_SERVER_URL + "auth/create_user", {
      username: data.get('username'),
      passwordHashed: data.get('password')}
    ).then((response) => {
      console.log(response);
      if (response.status === 200) {
        alert("User created successfully, redirecting to sign in page");
        navigate("/signin");
      }
    }).catch((error) => {
      console.log(error);
      alert("Could not create user: " + error.response.data);
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <PublicAppBar showBanner={false} />
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <NoEncryptionOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up for a new account
        </Typography>
        <Typography component="p" variant="caption">
          This is very insecure and you should <strong>not</strong> use a password you use elsewhere. I don't even trust that this account will continue to exist after a year.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordAgain"
            label="Password Again"
            type="password"
            id="passwordAgain"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                {"Already have an account? Sign in"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}